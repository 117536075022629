/**
 * A custom fieldset
 * @module
 */

import { Box, BoxProps } from "@mui/material"

/**
 * Properties from `BoxProps` mui type are passed
 * to the inner mui `Box` component
 */
type TFieldsetProps = BoxProps & {
	/** Display the fields horizontally. Defaults to false */
	horizontal?: boolean
}

/**
 * A custom fieldset
 *
 * Basically a [Box](https://mui.com/material-ui/react-box/) with styling
 *
 * Roughtly implemented as
 * ```
 * <Box
 * 	component="fieldset"
 * 	sx={/* Some styling *\/}
 * >
 * ```
 *
 * @group Components
 */
const Fieldset = (props: TFieldsetProps) => {
	const { sx: _sx, horizontal, ...others } = props
	return (
		<Box
			component="fieldset"
			sx={{
				display: "flex",
				flexWrap: "wrap",
				flexDirection: props.horizontal ? "row" : "column",
				rowGap: 2,
				columnGap: 1,
				borderStyle: "none",
				p: 0,
				mx: 0,
				...props.sx,
			}}
			{...others}
		>
			{props.children}
		</Box>
	)
}

export default Fieldset
export type { TFieldsetProps }
