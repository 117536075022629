/**
 * Object schema and conversion functions for Candidate Application data.
 * @module
 */

import { getPLValidator } from "backend/Picklist"
import { TPicklists } from "backend/Picklist/types"
import { object, string } from "yup"
import { undefinedToNull } from "utils/undefinedToNull"
import { enumOption, phoneNumber } from "utils/yup"
import { TDataSchema, TDataPost } from "./types"

/**
 * Generates a [yup](https://github.com/jquense/yup) schema describing a candidate application
 *
 * @remarks
 * This schema is then used by [formik](https://formik.org/) to generate form.
 * See {@link "components/Form/Form"}
 * It is also used to derive data types representing form data. See {@link "backend/CandidateApplication/types"}
 *
 * @param metadata - Data from success factor on which the schema depends
 * @returns A [yup](https://github.com/jquense/yup) schema describing candidate application data
 */
const getSchema = (metadata: { picklists: TPicklists }) => {
	const plValidator = getPLValidator(metadata.picklists)
	return object().shape({
		// Offer Info
		city: enumOption(plValidator.location).required().label("Ville"),
		jobFamily: enumOption(plValidator.custFunction)
			.required()
			.label("Famille de métier"),
		source: enumOption(plValidator.applicationSource)
			.optional()
			.label("source"),
		// Personal Info
		salutation: enumOption(plValidator.salutation)
			.required()
			.label("Etat civil"),
		firstName: string().max(100).required().label("Prénom"),
		lastName: string().max(100).required().label("Nom"),
		email: string().max(100).email().required().label("Email"),
		cellPhone: phoneNumber().required().label("Téléphone"),
		country: enumOption(plValidator.country).required().label("Pays"),
	})
}

/**
 * Convert data to a format accepted by the php backend
 *
 * @remark
 * This function converts undefined values to null using {@link undefinedToNull}
 *
 * @param formData - Form data validated and cast by the schema
 * @returns The candidate application data in a format accepted by the backend
 */
const intoBackendData = (formData: TDataSchema, fileId: number): TDataPost => {
	const result = undefinedToNull(formData) as TDataPost
	result["fileId"] = fileId
	return result
}

export default getSchema
export { intoBackendData }
