/**
 * Helper text representing an error
 * @module
 */

import * as React from "react"
import { Error } from "@mui/icons-material"

type TErrorHelperTextProps = {
	children: React.ReactNode
}

/**
 * Helper text representing an error
 *
 * Simply preprends an error icon to a node
 * @group Components
 */
const ErrorHelperText = (props: TErrorHelperTextProps) => {
	return (
		<React.Fragment>
			<Error fontSize="small" /> {props.children}
		</React.Fragment>
	)
}

export default ErrorHelperText
export type { TErrorHelperTextProps }
