import * as React from "react"
import { TPicklists } from "backend/Picklist"

/**
 * A context containing picklist options served to the whole application
 *
 * The idea is to fetch picklists values once and reuse them through the app
 */
const picklistContext = React.createContext<TPicklists>({
	location: [],
	contract: [],
	jobFamily: [],
	custFunction: [],
	salutation: [],
	levelOfEducation: [],
	applicationSource: [],
	school: [],
	languageLevel: [],
	salaryAssessment: [],
	interview1FollowUp: [],
	interview2FollowUp: [],
	country: [],
})

const usePicklists = () => React.useContext(picklistContext)

export { picklistContext, usePicklists }
