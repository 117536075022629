/**
 * ## TODO
 * Sending, receiving, and processing requests and responses should go through a client layer.
 * The client could do proper error handling
 *
 * @module
 */

import { TPicklists, TPicklistOption } from "backend/Picklist"

/**
 * Fetch every picklists from the php backend
 *
 * ## TODO
 * Add error management.
 * This function may return something that is not
 * a `TPicklists` if anything goes wrong on the php side.
 * Return type should be something like `Promise<TPicklists | ServerError>`
 * The whole logic should be delegated to a proper client.
 * See {@link backend/Picklist/queries}
 *
 * @returns A promise containing picklists data
 */
const getPicklists = async (): Promise<TPicklists> => {
	const request = {
		method: "GET",
	}
	const response = await fetch(
		process.env.REACT_APP_BACKEND_URL + "/Picklist.php",
		request
	)
	const picklists = (await response.json()) as Record<string, TPicklistOption[]>
	picklists["interview1FollowUp"] = picklists["interviewFollowUp"]
	picklists["interview2FollowUp"] = picklists["interviewFollowUp"].filter(
		(option) => option.label !== "Entretien niveau 2"
	)
	return picklists as TPicklists
}

export { getPicklists }
