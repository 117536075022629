/**
 * Error boundary when a page requested does'nt exist in route
 */

import React from "react"
import { Link } from "react-router-dom"
import icon from "assets/icon_404.svg"
import { Typography, Box } from "@mui/material"
import Accent from "components/StyleComponent/Accent"
import MainBox from "./MainBox"

const NotFound: React.FC = () => {
	return (
		<MainBox
			variant="column"
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					height: { xs: 89, md: 124 },
					width: { xs: 90, md: 125 },
				}}
			>
				<img style={{ height: "100%" }} src={icon} alt="404 icon"></img>
			</Box>
			<Typography variant="h2" sx={{ textAlign: "center" }}>
				<Accent>La page que vous demandez est introuvable.</Accent>
			</Typography>
			<Typography variant="h3">
				<Link to="/">Retourner à l&#39;accueil</Link>
			</Typography>
		</MainBox>
	)
}

export default NotFound
