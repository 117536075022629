/**
 * A heavily modified
 * [mui theme](https://mui.com/material-ui/customization/theming/)
 * to mimic [solutec.fr](https://www.solutec.fr/fr/)
 *
 * @module
 */

import * as React from "react"

import { FormControlProps, Theme } from "@mui/material"
import { createTheme } from "@mui/material/styles"
import "@mui/lab/themeAugmentation"
import type {} from "@mui/lab/themeAugmentation"

declare module "@mui/material/styles" {
	interface Theme {
		gap: (spacing: number) => number
	}
	interface ThemeOptions {
		gap: (spacing: number) => number
	}
}

declare module "@mui/material/styles" {
	interface TypographyVariants {
		accent: React.CSSProperties
		link: React.CSSProperties
	}
	interface TypographyVariantsOptions {
		accent?: React.CSSProperties
		link: React.CSSProperties
	}
}

declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		accent: true
		link: true
	}
}

/**
 * A heavily modified
 * [mui theme](https://mui.com/material-ui/customization/theming/)
 * to mimic [solutec.fr](https://www.solutec.fr/fr/)
 *
 * Breakpoints, colors and font sizes are directly extracted from
 * [solutec.fr](https://www.solutec.fr/fr/)
 *
 * Many components default are overriden, straying further from the
 * material guidelines to stick to solutec.fr design.
 * See the [component theming doc](https://mui.com/material-ui/customization/theme-components/)
 * from mui for more info
 */
let globalTheme = createTheme({
	spacing: 5,
	gap: (spacing: number) => 5 * spacing,
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 1024,
			lg: 1280,
			xl: 1445,
		},
	},
	palette: {
		primary: {
			light: "#ff5874",
			main: "#e90649",
			dark: "#af0022",
			contrastText: "#ffffff",
		},
		secondary: {
			light: "#f1f1f2",
			main: "#767a7d",
			dark: "#4a4e51",
			contrastText: "#ffffff",
		},
		info: {
			main: "#ffffff",
			contrastText: "#25272a",
		},
		text: {
			primary: "#545759",
		},
		error: {
			main: "#000000",
		},
	},
})

globalTheme = createTheme(globalTheme, {
	typography: {
		fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),
		accent: {
			fontWeight: 400,
		},
		link: {
			[globalTheme.breakpoints.up("lg")]: {
				fontSize: 18,
			},
			[globalTheme.breakpoints.down("lg")]: {
				fontSize: 16,
			},
		},
		body1: {
			lineHeight: 1.5,
			[globalTheme.breakpoints.up("lg")]: {
				fontSize: 21,
			},
			[globalTheme.breakpoints.down("lg")]: {
				fontSize: 18,
			},
		},
		body2: {
			lineHeight: 1.4,
			[globalTheme.breakpoints.up("lg")]: {
				fontSize: 18,
			},
			[globalTheme.breakpoints.down("lg")]: {
				fontSize: 16,
			},
		},
		h1: {
			fontFamily: ["Roboto Slab", "Roboto", "Arial", "sans-serif"].join(","),
			fontWeight: 400,
			[globalTheme.breakpoints.up("lg")]: {
				fontSize: 36,
			},
			[globalTheme.breakpoints.between("sm", "lg")]: {
				fontSize: 30,
			},
			[globalTheme.breakpoints.down("sm")]: {
				fontSize: 24,
			},
			letterSpacing: "0.01em",
			lineHeight: 1.25,
		},
		h2: {
			fontFamily: ["Roboto Slab", "Roboto", "Arial", "sans-serif"].join(","),
			fontWeight: 300,
			[globalTheme.breakpoints.up("lg")]: {
				fontSize: 36,
			},
			[globalTheme.breakpoints.between("sm", "lg")]: {
				fontSize: 30,
			},
			[globalTheme.breakpoints.down("sm")]: {
				fontSize: 24,
			},
			letterSpacing: 0,
		},
		h3: {
			fontFamily: ["Roboto Slab", "Roboto", "Arial", "sans-serif"].join(","),
			fontWeight: 400,
			[globalTheme.breakpoints.up("lg")]: {
				fontSize: 24,
			},
			[globalTheme.breakpoints.down("lg")]: {
				fontSize: 20,
			},
			letterSpacing: 0,
		},
		h4: {
			lineHeight: 1.5,
			fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),
			fontWeight: 450,
			[globalTheme.breakpoints.up("lg")]: {
				fontSize: 21,
			},
			[globalTheme.breakpoints.down("lg")]: {
				fontSize: 18,
			},
			letterSpacing: 0,
		},
		button: {
			fontSize: 18,
			fontWeight: 400,
			lineHeight: 1.5,
		},
	},
})

globalTheme = createTheme(globalTheme, {
	components: {
		MuiButtonBase: {
			defaultProps: {
				disableRipple: false,
			},
		},
		MuiAlert: {
			styleOverrides: {
				message: {
					display: "flex",
					flexDirection: "column",
					overflow: "visible",
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
					paddingLeft: 30,
					paddingRight: 30,
					paddingTop: 18,
					paddingBottom: 18,
					textTransform: "none",
					whiteSpace: "nowrap",
					minWidth: "min-content",
				},
				containedPrimary: {
					"&:hover": {
						backgroundColor: globalTheme.palette.primary.main,
					},
				},
				containedSecondary: {
					"&:hover": {
						backgroundColor: globalTheme.palette.secondary.main,
					},
				},
				textSecondary: {
					color: globalTheme.palette.text.primary,
				},
			},
			defaultProps: {
				disableElevation: true,
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
					textTransform: "none",
				},
				colorPrimary: {
					color: globalTheme.palette.primary.contrastText,
					backgroundColor: globalTheme.palette.primary.main,
					"&:hover": {
						backgroundColor: globalTheme.palette.primary.main,
					},
				},
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					borderRadius: 0,
					padding: 0,
					"&:focus-within": {
						outlineStyle: "solid",
						outlineWidth: 1,
						outlineColor: globalTheme.palette.primary.main,
					},
				},
				input: ({ theme }: { theme: Theme }) => ({
					paddingBottom: theme.spacing(2),
					paddingRight: theme.spacing(2),
					paddingTop: theme.spacing(2),
					paddingLeft: theme.spacing(2),
					boxSizing: "border-box",
				}),
			},
			defaultProps: {
				disableUnderline: true,
			},
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					height: "3em",
				},
			},
		},
		MuiTextField: {
			defaultProps: {
				variant: "filled",
				minRows: 3,
			},
		},
		MuiSelect: {
			defaultProps: {
				variant: "filled",
			},
			styleOverrides: {
				select: {
					display: "flex",
					alignItems: "center",
					height: "3em",
				},
			},
		},
		MuiInputAdornment: {
			styleOverrides: {
				root: {
					height: "unset",
					marginTop: "0px !important",
					marginRight: 0,
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					position: "static",
					transform: "none",
					marginBottom: (theme: Theme) => theme.spacing(1),
					color: globalTheme.palette.text.primary,
					"&.Mui-focused": {
						color: globalTheme.palette.text.primary,
					},
				},
				asterisk: {
					color: globalTheme.palette.primary.main,
				},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: ({ ownerState }: { ownerState: FormControlProps }) => ({
					...(ownerState.error
						? {
								position: "relative",
								outlineStyle: "solid",
								outlineWidth: 1,
								outlineColor: globalTheme.palette.error.main,
						  }
						: {}),
					...{
						paddingLeft: globalTheme.spacing(1),
						paddingRight: globalTheme.spacing(1),
						paddingTop: globalTheme.spacing(0),
						paddingBottom: globalTheme.spacing(0),
						flexGrow: 1,
					},
				}),
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				asterisk: {
					color: globalTheme.palette.primary.main,
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					"&.Mui-focused": {
						color: globalTheme.palette.text.primary,
					},
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					margin: 0,
					marginTop: globalTheme.spacing(1),
					marginBottom: globalTheme.spacing(1),
					display: "inline-flex",
					flexDirection: "row",
					alignItems: "center",
					columnGap: globalTheme.spacing(1),
				},
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				popper: {
					boxSizing: "border-box",
					outlineStyle: "solid",
					outlineWidth: 1,
					outlineColor: globalTheme.palette.secondary.light,
				},
			},
			defaultProps: {
				autoSelect: true,
				autoHighlight: true,
				componentsProps: {
					popper: {
						modifiers: [
							{
								name: "offset",
								options: {
									offset: [0, 2],
								},
							},
						],
					},
				},
			},
		},
		MuiAppBar: {
			defaultProps: {
				elevation: 0,
			},
			styleOverrides: {
				colorDefault: {
					color: globalTheme.palette.text.primary,
					fontWeight: globalTheme.typography.fontWeightRegular,
					backgroundColor: globalTheme.palette.background.default,
					fontFamily: globalTheme.typography.fontFamily,
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					transitionTimingFunction: "ease !important",
				},
			},
		},
		MuiPaper: {
			defaultProps: {
				elevation: 0,
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					textDecoration: "none",
					color: globalTheme.palette.text.primary,
				},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				labelContainer: {
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
				},
				label: {
					textAlign: "start",
				},
			},
		},
		MuiStepIcon: {
			styleOverrides: {
				root: {
					"&.Mui-error": {
						borderRadius: 25,
					},
				},
			},
		},
		MuiStepConnector: {
			styleOverrides: {
				lineVertical: {
					minHeight: "12px",
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: "24px",
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: `
        body {
          min-height: 100vh;
		  background-color: #f1f1f1;
        }
        #root {
					display: flex;
					flex-direction: column;
          min-height: 100vh;
					height: 100vh;
					max-height: 100vh;
        }
				div {
					overflow-wrap: anywhere;
				}
				*::placeholder {
					font-style: italic;
				}
      `,
		},
	},
})

export default globalTheme
