import schema from "./schema"

/**
 * An object representing an error coming from the php backend
 */
type TServerError = {
	message: string
	previous: TServerError | null
	code: number
	file: string
	line: number
}

/**
 *
 * @param obj - an object to check against {@link TServerError | TServerError schema}
 * @returns a type assertion saying that `obj` is of type {@link TServerError | `TServerError`}
 */
const isServerError = <T extends Record<string, unknown>>(
	obj: T
): obj is T & TServerError => {
	return schema.isValidSync(obj)
}

export { type TServerError, isServerError }
