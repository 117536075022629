/**
 * ## TODO
 * Sending, receiving, and processing requests and responses should go through a client layer.
 * The client could do proper error handling
 *
 * @module
 */

import { intoBackendData } from "./schema"
import { TDataGet, TDataSchema } from "./types"
import { checkError } from "utils/errors"

/**
 * Fetch a candidate file form data from the php backend
 *
 * ## TODO
 * Add error management.
 * This function may return something that is not
 * a TDataGet if anything goes wrong on the php side.
 * Return type should be something like `Promise<TDataGet | ServerError>`
 * The whole logic should be delegated to a proper client.
 * See {@link backend/CandidateFile/queries}
 *
 * @param candidateId - The id of the JobApplication
 * @param secretKey - The secret key to access the data : either the *view* or *modify* key
 * @returns A promise containing the data
 */
const getCandidateFile = async (
	candidateId: number,
	secretKey: string
): Promise<TDataGet> => {
	const request = {
		method: "GET",
	}
	const params = new URLSearchParams({
		applicationId: candidateId.toString(),
		secretKey: secretKey,
	})
	const response = await fetch(
		process.env.REACT_APP_BACKEND_URL + "/CandidateFile.php?" + params,
		request
	)

	await checkError(response)
	return await response.json()
}

/**
 * Push a candidate file form data to the php backend
 *
 * Connection details should be delegated to a proper client.
 * See {@link backend/CandidateFile/queries}
 *
 * @param id - The id of the JobApplication
 * @param key - The secret key to access the data : must be the *modify* key
 * @param form - Form data validated and cast by the schema (hence the type)
 * @returns A promise containing a response object
 */
const updateCandidateFile = async (
	id: number,
	key: string,
	form: TDataSchema
): Promise<Response> => {
	const payload0 = intoBackendData(form)
	const payload = {
		...payload0,
		applicationId: id,
		secretKey: key,
	}
	const request = {
		method: "POST",
		body: JSON.stringify(payload, (key, value) =>
			typeof value === "string" ? value.replaceAll("..\n", "..\u200B\n") : value
		),
		headers: {
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(
		process.env.REACT_APP_BACKEND_URL + "/CandidateFile.php",
		request
	)
	return response
}

export { getCandidateFile, updateCandidateFile }
