import * as React from "react"
import { TRatingScales } from "backend/RatingScales"

/**
 * A context containing rating scales served to the whole application
 *
 * The idea is to fetch rating scales once and reuse them through the app
 */
const ratingScaleContext = React.createContext<TRatingScales>({
	baseScale: [],
	optionalScale: [],
	preciseScale: [],
})

const useRatingScales = () => React.useContext(ratingScaleContext)

export { ratingScaleContext, useRatingScales }
