/**
 * Renders a confirmation message using [`<Alert>`](https://mui.com/material-ui/react-alert/)
 * @module
 */
import { Alert } from "@mui/material"

/**
 * Renders a confirmation message using [`<Alert>`](https://mui.com/material-ui/react-alert/)
 * @group Components
 */
const ApplicationSuccessAlert = () => {
	return <Alert severity="success">Candidature envoyée</Alert>
}

export default ApplicationSuccessAlert
