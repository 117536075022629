/**
 * A scrollable popup with a collapsable part
 *
 * @module
 */
import * as React from "react"
import {
	Modal,
	Collapse,
	Button,
	ButtonProps,
	Box,
	Typography,
	Paper,
	useTheme,
} from "@mui/material"
import { Error, ExpandMore } from "@mui/icons-material"

import { RollButton } from "components"

/**
 * {@link AlertDialog | `<AlertDialog>`} properties type
 */
type TAlertDialogProps = {
	/** If true the popup is rendered on screen */
	open: boolean
	/** Callback to run when the "Close" button is pressed
	 *
	 * You probably want to at least set `open` to `false`
	 */
	handleClose: () => void
	/** The main message */
	userMessage: React.ReactNode
	/** Additional information hidden in the collapsed section */
	moreInfo: React.ReactNode
}

const ExpandButton = (
	props: Omit<ButtonProps, "children"> & { expanded: boolean }
) => {
	const theme = useTheme()
	const { sx, expanded, ...otherProps } = props
	return (
		<Button
			color="inherit"
			sx={{
				display: "flex",
				flexDirection: "row",
				columnGap: 5,
				alignItems: "center",
				...sx,
			}}
			{...props}
		>
			Voir le détail
			<ExpandMore
				sx={{
					transform: expanded ? undefined : "rotate(-90deg)",
					transition: "transform 500ms ease-in",
				}}
			/>
		</Button>
	)
}

/**
 * A scrollable popup with a collapsable part
 *
 * Uses a [mui `<Modal>`](https://mui.com/material-ui/react-modal/)
 * to render the popup
 *
 * @group Components
 */
const AlertDialog = (props: TAlertDialogProps) => {
	const theme = useTheme()
	const [expanded, setExpanded] = React.useState(false)
	return (
		<Modal
			open={props.open}
			aria-labelledby="Server error"
			aria-describedby="The server encountered an internal error"
		>
			<Paper
				sx={{
					color: theme.palette.error.main,
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					maxHeight: "90vh",
					maxWidth: "90vw",
					overflow: "clip",
					width: "max-content",

					display: "flex",
					flexDirection: "column",
					rowGap: 2,
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						columnGap: 5,
						justifyContent: "space-between",
						alignItems: "flex-start",
						m: 8,
						marginBottom: 0,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							rowGap: 2,
							alignItems: "flex-start",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								columnGap: 2,
							}}
						>
							<Error />
							<Typography variant="h1">Erreur interne</Typography>
						</Box>
						{props.userMessage}
						<ExpandButton
							onClick={() => setExpanded((e) => !e)}
							expanded={expanded}
						/>
					</Box>
					<RollButton color="inherit" size="small" onClick={props.handleClose}>
						FERMER
					</RollButton>
				</Box>

				<Collapse
					collapsedSize={0}
					timeout={500}
					sx={{
						overflow: "scroll",
						minHeight: 0,
					}}
					in={expanded}
				>
					<Box sx={{ p: 8 }}>{props.moreInfo}</Box>
				</Collapse>
			</Paper>
		</Modal>
	)
}

export default AlertDialog
export type { TAlertDialogProps }
