/**
 * A small theme modifier used to make buttons round
 *
 * @module
 */
import { createTheme, Theme } from "@mui/material/styles"

/**
 * A small theme modifier used to make buttons round
 *
 * @param theme - The theme to modify
 * @return `theme` but with `MuiButton` and `MuiIconButton`
 * styles overriden to make the `root` slot round
 */
const roundButtonTheme = (theme: Theme) =>
	createTheme(theme, {
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						boxSizing: "border-box",
						padding: 0,
						minWidth: 40,
						maxWidth: 40,
						width: 40,
						minHeight: 40,
						maxHeight: 40,
						height: 40,
						borderRadius: "50%",
					},
				},
			},
			MuiIconButton: {
				styleOverrides: {
					root: {
						boxSizing: "border-box",
						padding: 0,
						minWidth: 40,
						maxWidth: 40,
						width: 40,
						minHeight: 40,
						maxHeight: 40,
						height: 40,
						borderRadius: "50%",
					},
				},
			},
		},
	})

export default roundButtonTheme
