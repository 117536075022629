/**
 * ## TODO
 * Sending, receiving, and processing requests and responses should go through a client layer.
 * The client could do proper error handling
 *
 * @module
 */

import { TDataGet, TDataSchema, intoBackendData } from "backend/InterviewReport"
import { checkError } from "utils/errors"

/**
 * Fetch an interview report form data from the php backend
 *
 * ## TODO
 * Add error management.
 * This function may return something that is not
 * a `TDataGet` if anything goes wrong on the php side.
 * Return type should be something like `Promise<TDataGet | ServerError>`
 * The whole logic should be delegated to a proper client.
 * See {@link backend/InterviewReport/queries}
 *
 * @param applicationId - The id of the JobApplication
 * @param secretKey - The secret key to access the data : either the *view* or *modify* key
 * @param interviewNumber - 1 or 2 for the first or second interview
 * @returns A promise containing the data
 */
const getInterview = async (
	applicationId: number,
	secretKey: string,
	interviewNumber: number
): Promise<TDataGet> => {
	const request = {
		method: "GET",
	}
	const params = new URLSearchParams({
		applicationId: applicationId.toString(),
		secretKey: secretKey,
	})
	const response = await fetch(
		process.env.REACT_APP_BACKEND_URL +
			"/Interview" +
			interviewNumber +
			".php?" +
			params,
		request
	)

	await checkError(response)
	return await response.json()
}

/**
 * Push an interview report form data to the php backend
 *
 * Connection details should be delegated to a proper client.
 * See {@link backend/InterviewReport/queries}
 *
 * @param id - The id of the JobApplication
 * @param key - The secret key to access the data : must be the *modify* key
 * @param interviewNumber - 1 or 2 for the first or second interview
 * @param form - Form data validated and cast by the schema (hence the type)
 * @returns A promise containing a response object
 */
const updateInterview = async (
	id: number,
	key: string,
	interviewNumber: number,
	form: TDataSchema
): Promise<Response> => {
	const payload0 = intoBackendData(form)
	const payload = {
		...payload0,
		applicationId: id,
		secretKey: key,
	}
	const request = {
		method: "POST",
		body: JSON.stringify(payload, (key, value) =>
			typeof value === "string" ? value.replaceAll("..\n", "..\u200B\n") : value
		),
		headers: {
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(
		process.env.REACT_APP_BACKEND_URL + "/Interview" + interviewNumber + ".php",
		request
	)
	return response
}

export { getInterview, updateInterview }
