/**
 * Object types and typguards representing rating scales
 * @module
 */

import { Marked } from "utils/marked"

/**
 * A wrapper type around `string`. See {@link Marked}
 */
type TBaseScale = Marked<string, "baseScale">
/**
 * A wrapper type around `string`. See {@link Marked}
 */
type TOptionalScale = Marked<string, "optionalScale">
/**
 * A wrapper type around `string`. See {@link Marked}
 */
type TPreciseScale = Marked<string, "preciseScale">

/**
 * A static list of rating scales useful for the front end
 *
 * Those are all the picklist defined in SF except for defaultScale.
 */
type TRatingScales = {
	baseScale: TBaseScale[]
	optionalScale: TOptionalScale[]
	preciseScale: TPreciseScale[]
}

/**
 * A typeguard that assert that a string belongs to a rating scale type
 *
 * @param ratingScale - A rating scale (effectively an array of `string`)
 * @param s - The string to test
 * @returns `true` if `s` belongs to `ratingScale`. `false` otherwise
 */
const isRSOption = <T extends string>(ratingScale: T[], s: string): s is T =>
	(ratingScale as string[]).includes(s)

/**
 * Generate a typeguard for every rating scale
 *
 * This function is useless. Please use {@link isRSOption} directly
 *
 * ## TODO
 * Replace every occurence of this function with a direct usage of {@link isRSOption}
 *
 * @param picklists - A collection of every rating scale useful to the front end
 * @returns - A collection of typeguards checking if a string belongs to some rating scale
 * @deprecated
 */
const getRSTypesguards = (
	ratingScales: TRatingScales
): {
	[K in keyof TRatingScales]: (s: string) => s is TRatingScales[K][number]
} => ({
	baseScale: (s: string): s is TBaseScale =>
		isRSOption(ratingScales.baseScale, s),
	optionalScale: (s: string): s is TOptionalScale =>
		isRSOption(ratingScales.optionalScale, s),
	preciseScale: (s: string): s is TPreciseScale =>
		isRSOption(ratingScales.preciseScale, s),
})

export type { TBaseScale, TOptionalScale, TPreciseScale, TRatingScales }
export { isRSOption, getRSTypesguards }
