/**
 * A styled button for the navigation bar mimicking
 * [solutec.fr](https://www.solutec.fr/fr/)
 *
 * @module
 */
import { Button, ButtonProps, SxProps } from "@mui/material"

import * as coord from "utils/coord"

/**
 * {@link BarButton | `<BarButton>`} properties type.
 *
 * Pass the `ButtonProps` to the underlying
 * [mui `<Button>`](https://mui.com/material-ui/react-button/)
 * component
 */
type TBarButtonProps = {
	/** On which side of the button to display the bar */
	barSide: coord.side
	/** The width of the bar */
	barSize: number
	/** Transition duration in ms when hovering */
	transitionSpeed: number
} & ButtonProps

/**
 * A styled button for the navigation bar mimicking
 * [solutec.fr](https://www.solutec.fr/fr/)
 *
 * @group Components
 */
const BarButton = (props: TBarButtonProps) => {
	const sxBar: SxProps = {
		position: "absolute",
		backgroundColor: "primary.main",
	} as const
	sxBar[props.barSide] = 0
	sxBar[coord.Size(props.barSide)] = 0
	sxBar[coord.cross(coord.Size(props.barSide))] = "100%"
	sxBar.transition = `${coord.Size(props.barSide)} ${props.transitionSpeed}ms`

	const sxBarHover: SxProps = {
		position: "absolute",
	} as const
	sxBarHover[coord.Size(props.barSide)] = props.barSize
	sxBarHover[coord.cross(coord.Size(props.barSide))] = "100%"

	const sxButtonStyle = {
		backgroundColor: "background.paper",
		color: "text.primary",
	}

	const sxButtonHoverStyle = {
		backgroundColor: "background.paper",
		color: "text.primary",
	}

	const sxButton: SxProps = {
		position: "relative",
		...sxButtonStyle,
		"&:hover": sxButtonHoverStyle,
		"&.selected": sxButtonHoverStyle,
		"&.selected > div": { ...sxBarHover },
		"& > div": { ...sxBar },
		"&:hover > div": { ...sxBarHover },
	} as const

	const {
		transitionSpeed: _a,
		barSide: _b,
		barSize: _c,
		sx: _d,
		children: _e,
		...others
	} = props

	return (
		<Button sx={{ ...sxButton, ...props.sx }} {...others}>
			<div></div>
			{props.children}
		</Button>
	)
}

BarButton.defaultProps = {
	barSize: 4,
	transitionSpeed: 300,
}

export default BarButton
export type { TBarButtonProps }
