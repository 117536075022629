/**
 * A component wrapping {@link components/Form/TextField!default | `<TextField>`}
 * to generate a picklist selector input
 * @module
 */

import { MenuItem } from "@mui/material"
import { TextField, TTextFieldProps } from "components"
import { isNotObsolete, TPicklistOption } from "backend/Picklist"

/**
 * {@link PicklistField | `<PicklistField>`} props type.
 * Almost identical to {@link TextField | `<TextField>`} props type: {@link TTextFieldProps}
 *
 * `select` is omitted from prop type as it is always set
 * on the underlying field.
 */
type TPicklistFieldProps = Omit<TTextFieldProps, "select"> & {
	/**
	 * A picklist used to generate options to choose from
	 *
	 * picklist options are filtered using {@link isNotObsolete}
	 * and rendered into their label
	 */
	picklist: TPicklistOption[]
}

/**
 * A component wrapping {@link TextField | `<TextField>`}
 * to generate a picklist selector input
 *
 * Sets the select prop on the underlying {@link TextField | `<TextField>`},
 * generates its children nodes from the picklist,
 * and pass it the remaining props
 *
 * @group Components
 */
const PicklistField = (props: TPicklistFieldProps) => {
	const { picklist, ...otherProps } = props
	return (
		<TextField select {...otherProps}>
			{picklist.filter(isNotObsolete).map((value) => (
				<MenuItem key={value.id} value={value.id}>
					{value.label}
				</MenuItem>
			))}
		</TextField>
	)
}

export default PicklistField
export type { TPicklistFieldProps }
