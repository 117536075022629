import { ObjectSchema, SchemaFieldDescription } from "yup"

/**
 * Typeguard to check that an object has the `type` property of the
 * [SchemaFieldDescription](https://github.com/jquense/yup/blob/1ee9b21c994b4293f3ab338119dc17ab2f4e284c/src/schema.ts#L126)
 * type from yup.
 *
 * @param obj - Any object.
 * @returns `true` if `obj.type` exists. `false` otherwise.
 */
const hasTypeDesc = <T extends object>(obj: T): obj is T & { type: string } => {
	return Object.prototype.hasOwnProperty.call(obj, "type")
}

/**
 * Converts a field type [infered from a yup schema](
 * https://github.com/jquense/yup#typescript-integration)
 * to the corresponding type for DOM input elements (`boolean` or `string`)
 */
type TField<TSchemaField> = TSchemaField extends boolean
	? boolean
	: TSchemaField extends number
	? string
	: string

/**
 * Converts a [yup schema](https://github.com/jquense/yup)'s output type
 * to the corresponding type for a DOM form.
 *
 * All fields are marked as non-optional, not `undefined` and their value
 * type is converted using {@link TField}
 */
type TForm<TSchema extends Record<string, unknown>> = {
	[K in keyof TSchema]-?: TField<Exclude<TSchema[K], undefined>>
}

/**
 * Generates initial default value for a DOM form using type info from a
 * [yup schema](https://github.com/jquense/yup).
 *
 * Instanciate every field to `""` excepte boolean fields that are instanciated
 * to `false`.
 *
 * @param schema - A yup schema whose output type is a simple string record
 * @returns Default initial values for a form using `schema` as its validation model
 */
const getFormInitialFromSchema = <TSchema extends Record<string, unknown>>(
	schema: ObjectSchema<TSchema>
): TForm<TSchema> => {
	const desc = schema.describe().fields as Record<
		keyof TSchema,
		SchemaFieldDescription
	>
	const formDefaultArray = Object.entries(desc).map(
		([fieldName, fieldDesc]) => {
			if (typeof fieldDesc !== "object") {
				throw new Error(
					`schema.describe returned an object with a property of type ${typeof fieldDesc}`
				)
			}
			if (fieldDesc === null) {
				throw new Error(`schema.describe returned a null field description`)
			}
			if (!hasTypeDesc(fieldDesc)) {
				throw new Error(
					`schema.describe returned a field description without a type`
				)
			}
			switch (fieldDesc.type) {
				case "string":
					return [fieldName, ""] as const
				case "number":
					return [fieldName, ""] as const
				case "boolean":
					return [fieldName, false] as const
				default:
					return [fieldName, ""] as const
			}
		}
	)
	return Object.fromEntries<"" | false>(formDefaultArray) as TForm<TSchema>
}

/**
 * Casts data (likely retreive from the php backend) to data that can be accepted
 * by a DOM form
 *
 * Casts every field value to a string except boolean values that are kept as is.
 *
 * @param schema - A generic string record holding data for a DOM form
 * @returns - The record whose field were all cast to `string` or `boolean`
 */
const getFormValuesFromData = <TData extends Record<string, unknown>>(
	data: TData
): Partial<TForm<TData>> => {
	const formFieldArray = Object.entries(data).map(([fieldName, value]) => {
		switch (typeof value) {
			case "number":
				return [fieldName, value.toString()] as const
			case "boolean":
				return [fieldName, value] as const
			default: {
				return [fieldName, String(value)] as const
			}
		}
	})
	return Object.fromEntries<string | boolean>(formFieldArray) as Partial<
		TForm<TData>
	>
}

/**
 * Generate initial data for a DOM form using existing data from the backend
 * and the associated yup schema to retreive type information.
 *
 * Use {@link getFormInitialFromSchema} to generate default value from the schema.
 * Use {@link getFormValuesFromData} to cast existing data to a compatible type.
 *
 * @param schema - A yup schema associated to the form you want to generate initial values for
 * @param existingData - Partial data object with pre-filled values for the form
 * @returns - `existingData` with missing fields filled with default init values generated from `schema`
 * and cast to a type that the DOM inputs can read
 */
const genFormInit = <
	TKey extends string,
	TSchema extends Record<TKey, unknown>,
	TData extends Partial<Record<TKey, unknown>>
>(
	schema: ObjectSchema<TSchema>,
	existingData: TData
): TForm<TSchema> => {
	const initDefault = getFormInitialFromSchema(schema)
	const existingDataInit = getFormValuesFromData(existingData)
	const res = Object.assign(initDefault, existingDataInit)
	return res
}

export { genFormInit }
export type { TField, TForm }
