/**
 * A popup feed fading on its own after a delay
 *
 * @module
 */
import * as React from "react"
import { Box, BoxProps, useTheme } from "@mui/material"

/* ABOUT PRERENDERS
 *
 * Notifications are positions absolutly in notification feed container.
 * This is done in order to animate the top property.
 *
 * Notifications appear to slide up or down when they are moved.
 * A flexbox layout cannot be animated as smoothly.
 *
 * To mimic the flexbox layout, we first preRender the feed in a classic flexlayout.
 * Just before the screen is painted, we record the position of the notifications.
 * The render is then aborted and a new render is started using absolute positioning
 * and the recorded positions
 *
 * Inspired by [a stackoverflow answer]
 * (https://stackoverflow.com/questions/11106876/is-it-possible-to-animate-flexbox-inserts-removes#answer-53618561)
 */

type TTimings = {
	/** Duration of the slide-in animation */
	slideInDuration: number
	/** Duration of the fade-out animation */
	fadeOutDuration: number
	/** Duration during which notifications stay before starting to fade */
	fadeOutDelay: number
}

/**
 * {@link NotificationFeed | `<NotificationFeed>`} properties type
 */
type TNotificationFeedProps = {
	timings: TTimings
}

/**
 * A notification popup
 *
 * It is basically a styled, absolutly positionned Box,
 * with three animations tied to it
 * @group Components
 */
const Notification = (
	props: BoxProps & {
		/** A key to identify the component between renders. Passed to the inner Box as an `id` attribute */
		reactKey: number
		/** Timing info for animations */
		timings: TTimings
		/**
		 * How far from the top of the container to render the Notification
		 *
		 * If undefined, we are doing a preRender.
		 * The underlying `<Box>` is then set to `display: static`
		 * and rendered according to flexbox layout.
		 * Its position will be recorded for the real render.
		 */
		top: number | undefined
	}
) => {
	const { sx, top: _t, reactKey: _rc, ...otherProps } = props

	// State and refs
	const containerRef = React.useRef<HTMLElement>(null)
	/** Stores the previous `top` value to detect change */
	const [top, setTop] = React.useState<number | undefined>(undefined)

	// Utils
	const px = (val: number) => val.toString() + "px"

	// Effects
	const animateTopRef = React.useRef<Animation | null>(null)
	const animateInRef = React.useRef<Animation | null>(null)
	const animateOutRef = React.useRef<Animation | null>(null)

	/**
	 * The animation to start when the `top` property changed.
	 *
	 * When a previous notification is removed from the feed.
	 * Other notifications slide up.
	 */
	const animateTopChange = () => {
		const el = containerRef.current!
		if (props.top !== undefined && top !== undefined && props.top !== top) {
			animateTopRef.current = el.animate(
				{ top: [px(top), px(props.top)] },
				{ duration: 500, easing: "ease-out" }
			)
		}
	}

	const animateTopChangeCleanup = () => {
		animateTopRef.current?.cancel()
	}

	const setOldTop = () => {
		if (props.top !== undefined) {
			setTop(props.top)
		}
	}

	const setOldTopCleanup = () => {
		// Do nothing ! We can't revert to old value (it is not stored anywhere)
		// But it doesn't matter
	}

	/**
	 * An effect registering two animations
	 *
	 * The slide in animation.
	 * And the fade out animation with a delay
	 */
	const animateInOut = () => {
		if (props.top !== undefined || props.top === undefined) {
			const el = containerRef.current!
			animateInRef.current = el.animate(
				{
					transform: [`translate(calc(100vw - ${el.offsetLeft}px))`, "none"],
				},
				{
					duration: props.timings.slideInDuration,
					easing: "cubic-bezier(0.17, 0.62, 0.1, 1.51)",
				}
			)
			animateOutRef.current = el.animate(
				{
					opacity: [1, 0],
				},
				{
					duration: props.timings.fadeOutDuration + props.timings.fadeOutDelay,
					delay: props.timings.fadeOutDelay,
					easing: "linear",
				}
			)
		}
	}

	const animateInOutCleanup = () => {
		animateInRef.current?.cancel()
		animateOutRef.current?.cancel()
	}

	/** Effects to run every time `top` changes */
	React.useEffect(() => {
		animateTopChange()
		setOldTop()
		return () => {
			animateTopChangeCleanup()
			setOldTopCleanup()
		}
	}, [props.top])

	/** Effects to run only once on mount */
	React.useEffect(() => {
		animateInOut()
		return animateInOutCleanup
	}, [])

	// Render
	return (
		<Box
			id={props.reactKey.toString()}
			ref={containerRef}
			sx={{
				...(props.top === undefined
					? {}
					: {
							position: "absolute",
							top: props.top,
							right: 0,
					  }),
				...sx,
			}}
			{...otherProps}
		>
			{props.children}
		</Box>
	)
}

/**
 * Describes a notification
 */
type TNotificationInfo = {
	/**
	 * A key used to match components between renders.
	 * See [React keys](// Store the previous top value to detect change)
	 * */
	key: number
	/** The node to render. `<Notification>` is always used here */
	node: React.ReactNode
}

/**
 * Generate a list of notification nodes to render
 *
 * @param timings - Timing parameters to pass to {@link Notification | `<Notification>`}
 * @param preRender - A flag indicating that we are preRendering the nodes to get their heights
 * @param notifHeights - Stored heights from a previous preRender
 * @param notifications - Notification informations
 * @returns A list of React Nodes to render
 */
const genNotifComponents = (
	timings: TTimings,
	preRender: boolean,
	notifHeights: Map<number, number>,
	notifications: TNotificationInfo[]
): React.ReactNode[] => {
	const res: React.ReactNode[] = []
	notifications.forEach((info) => {
		let top = undefined
		if (!preRender) {
			const topOffset = notifHeights.get(info.key)
			if (topOffset === undefined) {
				throw new Error("notif height not found")
			} else {
				top = topOffset
			}
		}
		res.push(
			<Notification
				key={info.key}
				reactKey={info.key}
				top={top}
				timings={timings}
			>
				{info.node}
			</Notification>
		)
	})
	return res
}

/**
 * Renders a list of notifications as popups in a vertical flexbox layout
 *
 * Notifications slide in when added to the list.
 * They fade out after a delay defined by `props.timings.fadeOutDelay`.
 * When a element is added or removed from the list, other animation slides up or down
 * to the new flexbox layout.
 *
 * ### Invariant
 * Notifications should be removed from the list after they finished fading.
 * i.e. after `props.timings.fadeOutDelay + props.timings.fadeOutDuration`
 *
 * @group components
 */
const NotificationFeed = (
	props: TNotificationFeedProps & {
		notifications: TNotificationInfo[]
	}
) => {
	// States and refs
	const containerRef = React.useRef<HTMLElement>()
	/** State holding the height information of the previous preRender */
	const [notifHeights, setNotifHeights] = React.useState<Map<number, number>>(
		new Map()
	)
	/**
	 * Indicates if we are doing a preRender or not
	 * Defined as a ref instead of a state because it shouldn't trigger reRender on every change
	 */
	const preRender = React.useRef<boolean>(true)

	// Effects
	const setPreRender = () => {
		"Props changed, set PreRender to true"
		preRender.current = true
	}

	/**
	 * After a preRender, retreive the height of notifications as layed out by the
	 * browser to use in the real render that follows
	 */
	const retreiveComputedFlexLayout = () => {
		if (preRender.current) {
			const container = containerRef.current!
			const notifs = Array.from(container.children)
			const newNotifHeights = notifs.map((element) => {
				const key = parseInt(element.id)
				const pos = (element as HTMLElement).offsetTop
				return [key, pos] as [number, number]
			})
			preRender.current = false
			setNotifHeights(new Map(newNotifHeights))
		}
	}

	const retreiveComputedFlexLayoutCleanup = () => {
		// Nothing to do, the effect run only once because of the condition anyway
	}

	/**
	 * `useMemo` is used as a hack to run a callback before render.
	 * see [stackoverflow - How to trigger useEffects before render in React?]
	 * (How to trigger useEffects before render in React?).
	 *
	 * Indeed, when the props change, we want to run a preRender.
	 * Setting preRender to true after the render would waste a whole render cycle.
	 */
	React.useMemo(() => {
		setPreRender()
	}, [props.notifications])

	/**
	 * Retreive the computed layout
	 * before the render is painted to screen by the browser to avoid flickering.
	 */
	React.useLayoutEffect(() => {
		retreiveComputedFlexLayout()
		return retreiveComputedFlexLayoutCleanup
	})

	/**
	 * The rendered node is mainly a box containing notifications
	 * generated by {@link genNotifComponents}.
	 *
	 * It is a flexbox container. The flexbox layout is only used during preRenders
	 * to compute layout heights. In real renders, notifications are positionned
	 * absolutly.
	 */
	const theme = useTheme()
	return (
		<Box
			ref={containerRef}
			sx={{
				position: "fixed",
				width: `calc(100% - 2 * ${theme.spacing(10)})`,
				display: "flex",
				alignItems: "flex-end",
				flexDirection: "column",
				rowGap: 5,
				top: theme.spacing(5),
				right: theme.spacing(10),
				zIndex: 2000,
			}}
		>
			{genNotifComponents(
				props.timings,
				preRender.current,
				notifHeights,
				props.notifications
			)}
		</Box>
	)
}

/**
 * A wrapper around {@link NotificationFeed | `<NotificationFeed>`}
 * that initializes and manages its state
 *
 * @return `[ManagedNotificationFeed, pushNotif]`
 * `ManagedNotificationFeed` is a notification feed with the props and state configured once and for all
 * `pushNotif(notif)` adds a notification to the feed
 *
 * @group Hooks
 */
const useNotificationFeed = (
	props: TNotificationFeedProps
): [React.FC<Record<string, never>>, (notif: React.ReactNode) => void] => {
	const [notifs, setNotifs] = React.useState<[TNotificationInfo[], number]>([
		[],
		0,
	])
	const notifsRef = React.useRef<TNotificationInfo[]>([])
	notifsRef.current = notifs[0]
	const Component = React.useCallback(
		() => <NotificationFeed notifications={notifsRef.current} {...props} />,
		[]
	)
	const pushNotif = (notif: React.ReactNode): void => {
		setNotifs(([notifs, key]) => [
			[...notifs, { key: key, node: notif }],
			key + 1,
		])
		setTimeout(() => {
			setNotifs(([notifs, key]) => [[...notifs.slice(1)], key])
		}, props.timings.fadeOutDelay + props.timings.fadeOutDuration)
	}
	return [Component, pushNotif]
}

export default useNotificationFeed
