/**
 * Boxes which width dynamically synchronise to be equal
 * @module
 */

import * as React from "react"

import { Box, BoxProps } from "@mui/material"

type TWidths = Record<string, number | undefined>

type TSyncWidthBoxProps = {
	/** Expects a react state generated by {@link useSyncWidth}
	 *
	 * {@link SyncWidthBox | SyncWidthBoxes } sharing the same state are dynamically adjusted to have the same exact width
	 */
	widthsState: [TWidths, React.Dispatch<React.SetStateAction<TWidths>>]
	/**
	 * A key identifying the box.
	 *
	 * Any string as long as they are unique among all {@link SyncWidthBox | SyncWidthBoxes } sharing a state.
	 * As any React key, it **shouldn't change between renders**
	 */
	syncKey: string
} & BoxProps

const maxWidth = (widths: Record<string, number | undefined>): number =>
	Object.values(widths)
		.filter((w): w is number => w !== undefined)
		.reduce((prevw, w) => (prevw < w ? w : prevw), 0)

/**
 * Generates a new synchronisation state
 *
 * You should generate a new state for each synchronized group of {@link SyncWidthBox | SyncWidthBoxes }
 *
 * @group Hooks
 */
const useSyncWidth = () => React.useState<TWidths>({})

/**
 * A Box component which width synchronises with others
 *
 * @param props
 */
const SyncWidthBox = (props: TSyncWidthBoxProps) => {
	const {
		syncKey: _sk,
		widthsState: [widths, setWidths],
		children: _ch,
		sx: _sx,
		...others
	} = props
	const divRef = React.useRef<HTMLDivElement | null>(null)
	React.useEffect(() =>
		setWidths((ws) => {
			const divWidth =
				divRef.current === null
					? undefined
					: Number.parseFloat(window.getComputedStyle(divRef.current).width)
			if (ws[props.syncKey] === divWidth) {
				return ws
			} else {
				return {
					...ws,
					[props.syncKey]: divWidth,
				}
			}
		})
	)
	return (
		<Box
			ref={divRef}
			sx={{
				minWidth: maxWidth(widths),
				...props.sx,
			}}
			{...others}
		>
			{props.children}
		</Box>
	)
}

export default SyncWidthBox
export { useSyncWidth }
export type { TSyncWidthBoxProps }
