import * as React from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

import {
	Typography,
	ThemeProvider,
	Alert,
	Button,
	SvgIcon,
	useTheme,
	Box,
} from "@mui/material"
import { FormikHelpers } from "formik"

import {
	MainBox,
	Fieldset,
	ApplicationSuccessAlert,
	useNotificationFeed,
	PicklistField,
	Form,
	TextField,
} from "components"
import { Link } from "react-router-dom"
import formTheme from "themes/form"
import {
	getSchema,
	TDataForm,
	sendCandidateApplication,
	sendResumeFile,
	TDataSchema,
} from "backend/CandidateApplication"
import { genFormInit } from "utils/form"
import { usePicklists } from "backend/Picklist"
import { TPicklists } from "backend/Picklist/types"
import { isServerError, schema as errorSchema } from "backend/ServerError"
import useServerErrorAlert from "components/Form/ServerErrorAlert"

import UndoIcon from "@mui/icons-material/Undo"

type TURLParams = {
	city?: string
	jobFamily?: string
}

const FormContent = (props: {
	picklists: TPicklists
	spontaneousApplication: boolean
}) => (
	<React.Fragment>
		<Fieldset>
			<Fieldset horizontal>
				<PicklistField
					name="salutation"
					picklist={props.picklists.salutation}
				/>
				<TextField name="firstName" />
				<TextField name="lastName" />
			</Fieldset>
			<TextField name="email" type="email" />
			<TextField name="cellPhone" />
			<PicklistField name="country" picklist={props.picklists.country} />
		</Fieldset>
		{props.spontaneousApplication ? (
			<Fieldset>
				<Typography variant="h2">Poste souhaité</Typography>
				<PicklistField name="city" picklist={props.picklists.location} />
				<PicklistField
					name="jobFamily"
					picklist={props.picklists.custFunction}
				/>
			</Fieldset>
		) : null}
	</React.Fragment>
)

/**
 * ## TODO integrate the file input better with formik and mui
 * The page to file in candidate application forms
 *
 * @returns
 */
const CandidateApplication = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const param: TURLParams = useParams() as TURLParams

	const theme = useTheme()

	let spontaneousApplication = true
	let existingData: Partial<TDataSchema> = {}
	if (param.city !== undefined && param.jobFamily !== undefined) {
		spontaneousApplication = false
		existingData = {
			city: parseInt(param.city),
			jobFamily: parseInt(param.jobFamily),
		}
	}
	const picklists = usePicklists()
	let source
	if (searchParams.get("source") !== null) {
		source = picklists.applicationSource.find(
			(option) => option.externalCode === searchParams.get("source")
		)?.id
	}
	if (source === undefined || spontaneousApplication) {
		source = picklists.applicationSource.find(
			(option) => option.externalCode === "spontApplication"
		)?.id
	}
	existingData["source"] = source

	const metadata = {
		picklists: picklists,
	}
	const schema = getSchema(metadata)

	const [file, setFile] = React.useState<File>()
	const [errorFile, setErrorFile] = React.useState("")
	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		if (event.target.files) {
			const newFile = event.target.files[0]
			const authorizedExtensions = ["doc", "docx", "html", "pdf", "rtf", "txt"]
			if (newFile) {
				if (
					authorizedExtensions.includes(newFile.name.split(".").pop() ?? "")
				) {
					if (newFile.size <= 5242880) {
						setFile(newFile)
						setErrorFile("")
					} else {
						setFile(undefined)
						setErrorFile("Fichiers de taille supérieure à 5 MB non acceptés")
					}
				} else {
					setFile(undefined)
					setErrorFile("Format de fichier non accepté")
				}
			} else {
				setFile(undefined)
				setErrorFile("CV est un champ obligatoire")
			}
		}
	}

	const handleSubmit = async (
		data: TDataForm,
		formikHelpers: FormikHelpers<TDataForm>
	) => {
		if (!file) {
			setErrorFile("CV est un champ obligatoire")
			formikHelpers.setSubmitting(false)
			return
		}

		const fileResponse = await sendResumeFile(file)
		const fileResponseData = await fileResponse.json()
		if (!fileResponse.ok) {
			if (!isServerError(fileResponseData)) {
				throw new Error(errorSchema.validateSync(fileResponseData).toString())
			}
			setError(fileResponseData)
			formikHelpers.setSubmitting(false)
			return
		}

		const response = await sendCandidateApplication(
			schema.validateSync(data),
			fileResponseData["id"]
		)
		if (!response.ok) {
			const responseData = await response.json()
			if (!isServerError(responseData)) {
				throw new Error(errorSchema.validateSync(responseData).toString())
			}
			setError(responseData)
			formikHelpers.setSubmitting(false)
		} else {
			pushNotif(<ApplicationSuccessAlert />)
			await new Promise((resolve) => setTimeout(resolve, 1000))
			navigate("/")
		}
	}
	const [ErrorAlert, setError] = useServerErrorAlert()
	const [NotificationFeed, pushNotif] = useNotificationFeed({
		timings: {
			slideInDuration: 500,
			fadeOutDelay: 2000,
			fadeOutDuration: 2000,
		},
	})
	return (
		<ThemeProvider theme={formTheme}>
			<ErrorAlert />
			<NotificationFeed />
			<MainBox
				variant="column"
				id="mainBox"
				sx={{ backgroundColor: theme.palette.background.paper }}
			>
				<Box>
					<Button sx={{ gap: "10px" }} href="/">
						<SvgIcon component={UndoIcon} />
						<Typography>Retour</Typography>
					</Button>
				</Box>
				<Typography variant="h1">Postuler</Typography>
				<Form
					schema={schema}
					initialValues={genFormInit(schema, existingData)}
					onSubmit={handleSubmit}
					disabled={false}
				>
					<FormContent
						picklists={picklists}
						spontaneousApplication={spontaneousApplication}
					/>
					<Typography variant="subtitle1">
						CV <span style={{ color: "red" }}>*</span>{" "}
						<i style={{ color: "gray", fontSize: "80%" }}>
							(Formats acceptés : pdf, rtf, doc, docx, html, txt)
						</i>
					</Typography>
					<input
						type="file"
						onChange={handleChange}
						accept=".pdf, .rtf, .doc, .docx, .html, .txt, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/xhtml+xml, application/pdf, application/rtf, text/plain, text/html, text/rtf"
					></input>
					{errorFile !== "" ? (
						<Alert severity="error">{errorFile}</Alert>
					) : null}
				</Form>
			</MainBox>
		</ThemeProvider>
	)
}

export default CandidateApplication
