import React from "react"
import ReactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"
import { createBrowserRouter, RouterProvider } from "react-router-dom"

import Root, { loader as rootLoader } from "routes/Root"

import CandidateFile, {
	loader as candidateFileLoader,
} from "routes/CandidateFile"
import Interview, { loader as interviewLoader } from "routes/Interview"
import JobPosting from "routes/JobPosting"
import CandidateApplication from "routes/CandidateApplication"
import NotFound from "components/LayoutAndSurfaces/NotFound"
import Home from "routes/Home"
import ErrorPage from "components/LayoutAndSurfaces/ErrorPage"

const router = createBrowserRouter([
	{
		path: "/",
		element: <Root />,
		loader: rootLoader,
		// No need to run the rootLoader again at any time during navigation
		shouldRevalidate: () => false,
		children: [
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "candidateFile/:candidateId/:permission/:key",
				element: <CandidateFile />,
				loader: candidateFileLoader,
				errorElement: <ErrorPage />,
			},
			{
				path: "interview/:interviewNumber/:applicationId/:permission/:key",
				element: <Interview />,
				loader: interviewLoader,
				errorElement: <ErrorPage />,
			},
			{
				path: "carriere/:id",
				element: <JobPosting />,
				errorElement: <ErrorPage />,
			},
			{
				path: "*",
				element: <NotFound />,
			},
			{
				path: "apply/:city?/:jobFamily?",
				element: <CandidateApplication />,
			},
		],
	},
])

document.documentElement.lang = "fr"
document.documentElement.translate = false

const rootSlotOrNull: HTMLElement | null = document.getElementById("root")
if (rootSlotOrNull === null) throw new Error("ROOT SLOT NOT FOUND")
const rootSlot: HTMLElement = rootSlotOrNull

const root = ReactDOM.createRoot(rootSlot)
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
