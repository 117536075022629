/**
 * Object schema and conversion functions for Candidate Files data.
 * @module
 */

import { getPLValidator } from "backend/Picklist"
import { TPicklists } from "backend/Picklist/types"
import { object, string, boolean, ref } from "yup"
import { undefinedToNull } from "utils/undefinedToNull"
import {
	date,
	enumOption,
	phoneNumber,
	numberEmptyStringToUndefined as number,
} from "utils/yup"
import { TDataSchema, TDataPost } from "./types"

/**
 * Generates a [yup](https://github.com/jquense/yup) schema describing a candidate file
 *
 * @remarks
 * This schema is then used by [formik](https://formik.org/) to generate form.
 * See {@link "components/Form/Form"}
 * It is also used to derive data types representing form data. See {@link "backend/CandidateFile/types"}
 *
 * @param metadata - Data from success factor on which the schema depends
 * @returns A [yup](https://github.com/jquense/yup) schema describing candidate file data
 */
const getSchema = (metadata: { picklists: TPicklists }) => {
	const today = new Date()
	const yesterday = new Date()
	yesterday.setDate(yesterday.getDate() - 1)

	const plValidator = getPLValidator(metadata.picklists)
	return object().shape({
		// Personal Info
		salutation: enumOption(plValidator.salutation)
			.required()
			.label("Etat civil"),
		firstName: string().max(100).required().label("Prénom"),
		lastName: string().max(100).required().label("Nom"),
		residenceAdress: string()
			.max(4000)
			.required()
			.label("Nom et numéro de voie"),
		residenceState: string().max(100).required().label("Code postal"),
		residenceCity: string().max(100).required().label("Ville"),
		contactEmail: string().max(100).email().required().label("Email"),
		cellPhone: phoneNumber().required().label("Téléphone"),
		dateOfBirth: date()
			.required()
			.max(yesterday.toDateString())
			.label("Date de naissance"),
		placeOfBirth: string().max(100).required().label("Lieu de naissance"),
		countryOfBirth: enumOption(plValidator.country)
			.required()
			.label("Pays de naissance"),
		citizenship: string().max(100).required().label("Nationalité"),
		// Bachelors
		bachelorsSpecialty: string()
			.max(100)
			.required()
			.label("Série / Spécialité"),
		bachelorsYear: number()
			.integer()
			.min(0)
			.max(today.getFullYear())
			.required()
			.label("Année d'obtention du diplôme"),
		bachelorsMention: string().max(100).label("Mention"),
		//Training
		levelOfEducation: enumOption(plValidator.levelOfEducation)
			.required()
			.label("Niveau d'étude"),
		diploma: string().max(100).required().label("Diplôme principal"),
		school: enumOption(plValidator.school)
			.required()
			.label("Ecole / Université"),
		otherSchool: string().max(100).label("Autre école"),
		diplomaYear: number().integer().min(0).required().label("Année"),
		diplomaMention: string().max(100).label("Mention"),
		otherDiplomas: string().max(4000).label("Autres diplômes"),
		certifications: string()
			.max(4000)
			.label("Formations complémentaires / certifications"),
		// ForeignLanguages
		englishLevel: enumOption(plValidator.languageLevel)
			.required()
			.label("Anglais"),
		englishTests: string()
			.max(4000)
			.label("Tests d'anglais(TOEIC, TOEFL, FCE, ...)"),
		otherLanguages: string()
			.max(4000)
			.label("Autres langues - préciser le niveau"),
		// EmployementInternship
		employementInternshipCompany: string().max(100).required().label("Société"),
		employementInternshipStartDate: date()
			.required()
			.max(yesterday.toDateString())
			.label("Date de début"),
		employementInternshipEndDate: date()
			.min(ref("employementInternshipStartDate"))
			.label("Date de fin"), // TODO: validate that the date is after start date (currently is after startdate - 1)
		employementInternshipPosition: string()
			.max(4000)
			.required()
			.label("Intitulé et description du poste"),
		employementInternshipEnvironment: string()
			.max(4000)
			.required()
			.label("Environnement technique et/ou fonctionnel"),
		employementInternshipManager: string()
			.max(100)
			.label("Nom du responsable hiérarchique"),
		employementInternshipReason: string()
			.max(4000)
			.label("Motif du désir de changement"),
		employementInternshipStartSalary: number()
			.integer()
			.min(0)
			.label("Rémunération annuelle brute d'entrée"),
		employementInternshipExitSalary: number()
			.integer()
			.min(0)
			.label("Rémunération annuelle brute de sortie"),
		// References
		info1: string().max(100).label("Nom, prénom et entreprise"),
		email1: string().max(100).email().label("E-mail"),
		phone1: phoneNumber().label("Téléphone"),
		info2: string().max(100).label("Nom, prénom et entreprise"),
		email2: string().max(100).email().label("E-mail"),
		phone2: phoneNumber().label("Téléphone"),
		consent: boolean()
			.required()
			.label(
				"J’autorise SOLUTEC à contacter les personnes citées dans le cadre d’un contrôle de référence"
			)
			.when(
				["info1", "email1", "phone1", "info2", "email2", "phone2"],
				(fields: string[], schema) => {
					if (fields.some((s) => s !== undefined && s !== "")) {
						return schema.isTrue()
					} else {
						return schema
					}
				}
			),
		// Desired activity
		desiredFunction: string().max(100).required().label("Fonction"),
		desiredSalary: number()
			.required()
			.integer()
			.min(0)
			.label("Salaire annuel brut"),
		desiredCity: enumOption(plValidator.location)
			.required()
			.label("Etablissement de rattachement"),
		desiredAvailability: date()
			.required()
			.label("Date de disponibilité")
			.min(today.toDateString()),
		// About you
		aboutYouStrength: string()
			.max(4000)
			.required()
			.label("Principales forces et qualités"),
		aboutYouWeaknesses: string()
			.max(4000)
			.required()
			.label("Principales faiblesses"),
		aboutYouSources: string()
			.max(4000)
			.required()
			.label("Comment avez-vous connu Solutec ?"),
		// Privacy Plicy
		privacyConsent: boolean()
			.required()
			.isTrue()
			.label(
				"J'ai lu et j'accepte les termes de la politique de confidentialité"
			),
	})
}

/**
 * Convert data to a format accepted by the php backend
 *
 * @remark
 * This function converts undefined values to null using {@link undefinedToNull}
 *
 * @param formData - Form data validated and cast by the schema
 * @returns The candidate file data in a format accepted by the backend
 */
const intoBackendData = (formData: TDataSchema): TDataPost =>
	undefinedToNull(formData)

export default getSchema
export { intoBackendData }
