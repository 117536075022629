/**
 * @module RecentOfferAndTitle
 * This module provides a React functional component that displays recents (number may be chosen) job postings in a carousel format
 * This module is intended to be at top of the AllOffer page, so it also have a title in it
 */
import * as React from "react"

import { Link } from "react-router-dom"
import DOMPurify from "dompurify"

import { Box, Typography, IconButton, useTheme } from "@mui/material"
import { ArrowRight, ArrowLeft } from "@mui/icons-material"

import { usePicklists } from "backend/Picklist"
import { TJobPosting } from "backend/JobPosting"
import { Accent } from "components"

import { Carousel } from "react-responsive-carousel"
// Needed to display carousel properly
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./carousel-override.css"

/**
 * Type definition for RecentOfferAndTitle props
 * @typedef {Object} TRecentOfferAndTitleProps
 * @property {TJobPosting[]} data - Array of job postings (all available)
 * @property {number} count - Number of job postings
 */
type TRecentOfferAndTitleProps = {
	data: TJobPosting[] | undefined
	count: number | undefined
}

/**
 * RecentOfferAndTitle component
 * @param {TRecentOfferAndTitleProps} props - The properties passed to the component
 * @returns {JSX.Element} Returns a carousel displaying recent job postings
 */
const RecentOfferAndTitle = (props: TRecentOfferAndTitleProps) => {
	const { location, contract } = usePicklists()
	const [currentSlide, setCurrentSlide] = React.useState(0)

	const handlePrev = () => {
		setCurrentSlide((prev) => prev - 1)
	}

	const handleNext = () => {
		setCurrentSlide((prev) => prev + 1)
	}

	const theme = useTheme()

	return (
		<Box
			component={"article"}
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				paddingY: "2rem",
			}}
		>
			<Box
				sx={{
					marginBottom: "2rem",
					marginTop: { xs: "1rem" },
					textAlign: "center",
					color: theme.palette.secondary.light,
				}}
			>
				<Typography
					variant="h2"
					sx={{
						fontWeight: "bold",
					}}
				>
					<Accent
						sx={{
							fontWeight: "bold",
						}}
					>
						Nos offres
					</Accent>{" "}
					d&#39;emploi
				</Typography>
				<Typography variant="subtitle1">
					Effectuez une recherche parmi nos {props.count} offres
				</Typography>
			</Box>

			<Box sx={{ marginBottom: "0.5em" }}>
				<Typography variant="subtitle1" color={theme.palette.primary.main}>
					Nos offres les plus récentes
				</Typography>
			</Box>

			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-evenly",
				}}
			>
				<IconButton
					aria-label="left"
					onClick={handlePrev}
					sx={{ scale: "2", transform: "translateY(-12px)" }}
				>
					<ArrowLeft color="primary" sx={{ scale: "2" }} />
				</IconButton>

				<Box sx={{ width: "70%" }}>
					<Carousel
						selectedItem={currentSlide}
						onChange={(index) => setCurrentSlide(index)}
						showStatus={false}
						infiniteLoop={true}
						showArrows={false}
						showThumbs={false}
						dynamicHeight
						renderItem={(item, index) => {
							if (index !== undefined)
								return (
									<Box
										key={currentSlide}
										sx={{
											transform: index.isSelected ? "scale(1)" : "scale(0.5)",
											transition: "transform 0.5s ease-in-out",
										}}
									>
										{item}
									</Box>
								)
						}}
						showIndicators={true}
					>
						{props.data?.map((item, i) => {
							// get location and family name
							const loc = location.find((l) => l.id === item.location)?.label
							const type = contract.find((c) => c.id === item.jobType)?.label

							const desc = DOMPurify.sanitize(item.jobDescription, {
								ALLOWED_ATTR: [],
								// html tags break the carousel
								ALLOWED_TAGS: [],
							})

							return (
								<Box
									key={i}
									sx={{
										padding: { xs: "0.1em", sm: "0.5em", md: "1em" },
										backgroundColor: "#fff",
										borderRadius: { xs: "0.1em", sm: "0.5em", md: "1em" },
									}}
								>
									<Link
										to={`/carriere/${item.id}`}
										style={{ textDecoration: "none" }}
									>
										<Typography variant="h4" sx={{ marginX: "auto" }}>
											<Accent sx={{ fontWeight: "bold" }}>
												{item.jobTitle}
											</Accent>
										</Typography>
									</Link>
									<Typography variant="subtitle1">{loc}</Typography>
									<Typography variant="subtitle2">{type}</Typography>
									<Typography
										variant="body2"
										dangerouslySetInnerHTML={{ __html: desc }}
										sx={{
											overflow: "hidden",
											textOverflow: "ellipsis",
											display: "-webkit-box",
											WebkitLineClamp: { xs: 4, sm: 3, md: 2 },
											WebkitBoxOrient: "vertical",
											marginX: "auto",
										}}
									></Typography>
								</Box>
							)
						})}
					</Carousel>
				</Box>
				<IconButton
					aria-label="right"
					onClick={handleNext}
					sx={{ scale: "2", transform: "translateY(-12px)" }}
				>
					<ArrowRight color="primary" sx={{ scale: "2" }} />
				</IconButton>
			</Box>
		</Box>
	)
}

export default RecentOfferAndTitle
