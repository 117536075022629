import * as React from "react"

import { Grid, TablePagination, Box } from "@mui/material"

import { TJobPosting } from "backend/JobPosting"
import { useAsyncValue, Await, useSearchParams } from "react-router-dom"
import {
	MainBox,
	PreviewCard,
	SearchBar,
	RecentOfferAndTitle,
	SpontaneousApplication,
	LoadingCircle,
	BackgroundHeader,
	ButtonScrollDown,
} from "components"
import { useSearchContext } from "hooks/useSearchContext"
import { usePicklists } from "backend/Picklist"
import { useJobPostings } from "backend/JobPosting"

import background from "assets/bg_primary.jpeg"

const NUMBER_OF_RECENT_JOBPOSTINGS_DISPLAYED = 5

/**
 * This component is currently useless, but it can be upgraded if ever you wan't to use deferred data specific to this component
 * Currently the data is loaded once whenever you access the website, thx to root component
 *
 * @returns React fragment containing ux loading logic
 */
const Home = () => {
	const jobPostings = useJobPostings()

	return (
		<React.Suspense fallback={<LoadingCircle />}>
			<Await
				resolve={jobPostings}
				errorElement={
					<p>Une erreur est survenue... Essayer de recharger la page</p>
				}
			>
				<HomeContent />
			</Await>
		</React.Suspense>
	)
}

const HomeContent = () => {
	const jobPostings = useAsyncValue() as TJobPosting[]

	// For header
	const recentJobOffers: TJobPosting[] = jobPostings
		.sort((offerA, offerB) => offerB.id - offerA.id)
		.slice(0, NUMBER_OF_RECENT_JOBPOSTINGS_DISPLAYED)

	// Filterable
	const picklists = usePicklists()

	const [searchParams] = useSearchParams()
	const { searchinput, locationinput, jobtypeinput, setjobtype } =
		useSearchContext()

	React.useEffect(() => {
		const jobType = searchParams.get("jobType")
		if (jobType != null) {
			const parsedJobType = jobType
				.split(",")
				.map((jt) => Number(jt))
				.filter((jt) => picklists.contract.find((c) => c.id === jt) != null)
			setjobtype(parsedJobType)
		}
	}, [searchParams, setjobtype, picklists.contract])

	function filterJobPostings(jobPostings: TJobPosting[]): TJobPosting[] {
		const filtered = jobPostings.filter((offer) => {
			let dataValidation1 = true
			let dataValidation2 = true
			let dataValidation3 = true

			const sectorName = picklists.jobFamily?.find(
				(sector) => sector.id === offer.sector
			)?.label
			if (searchinput !== "") {
				dataValidation1 =
					offer.candidateProfile
						.toLowerCase()
						.includes(searchinput.toLowerCase()) ||
					offer.jobDescription
						.toLowerCase()
						.includes(searchinput.toLowerCase()) ||
					sectorName?.toLowerCase().includes(searchinput.toLowerCase()) ||
					offer.jobReference
						.toLowerCase()
						.includes(searchinput.toLowerCase()) ||
					offer.jobTitle.toLowerCase().includes(searchinput.toLowerCase()) ||
					offer.jobStartdate
						.toLowerCase()
						.includes(searchinput.toLowerCase()) ||
					offer.companyProfile
						.toLowerCase()
						.includes(searchinput.toLowerCase()) ||
					offer.experience.toLowerCase().includes(searchinput.toLowerCase()) ||
					(!offer.hideSalary &&
						((offer.salaryFrom
							?.toLowerCase()
							.includes(searchinput.toLowerCase()) ??
							false) ||
							(offer.salaryTo
								?.toLowerCase()
								.includes(searchinput.toLowerCase()) ??
								false)))
			}

			if (locationinput !== -1) {
				dataValidation2 = offer.location === locationinput
			}

			if (jobtypeinput.length !== 0) {
				dataValidation3 = jobtypeinput.includes(offer.jobType)
			}

			return dataValidation1 && dataValidation2 && dataValidation3
		})

		return filtered
	}

	const filteredJobPostings = jobPostings ? filterJobPostings(jobPostings) : []

	// Pageable
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(25)

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	return (
		<React.Fragment>
			<ButtonScrollDown href="#all-job-postings"></ButtonScrollDown>
			<BackgroundHeader backgroundPath={background} height="fit-content">
				<Grid container spacing={0}>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={8}
						sx={{ display: "flex", alignItems: "center" }}
					>
						<RecentOfferAndTitle
							data={recentJobOffers}
							count={jobPostings.length}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={4}>
						<SpontaneousApplication />
					</Grid>
				</Grid>
			</BackgroundHeader>
			<MainBox variant="column" id="all-job-postings">
				<Box>
					<SearchBar orientation="row" navigateOnReset={false} />
				</Box>
				<Grid container direction="column" spacing={2}>
					{(rowsPerPage > 0
						? filteredJobPostings
							? filteredJobPostings.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
							  )
							: []
						: filteredJobPostings
						? filteredJobPostings
						: []
					).map((jobOffer) => (
						<Grid item key={jobOffer.id}>
							<PreviewCard jobPosting={jobOffer} />
						</Grid>
					))}

					<TablePagination
						rowsPerPageOptions={[5, 10, 25, 50]}
						component="div"
						count={filteredJobPostings ? filteredJobPostings?.length : 0}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						title="Offres par page"
						labelRowsPerPage="Offres par page"
						labelDisplayedRows={({ from, to, count }) => {
							return `${from}–${to} de ${
								count !== -1 ? count : `plus que ${to}`
							} offres`
						}}
					/>
				</Grid>
			</MainBox>
		</React.Fragment>
	)
}

export default Home
