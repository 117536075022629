import * as React from "react"
import { TJobPosting } from "backend/JobPosting"

/**
 * A context containing all job posting served to the whole application
 *
 * The idea is to fetch data once and reuse them through the app
 */
const jobPostingsContext = React.createContext<TJobPosting[]>([])

const useJobPostings = () => React.useContext(jobPostingsContext)

export { jobPostingsContext, useJobPostings }
