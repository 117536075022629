import * as React from "react"
import { Await, defer, Outlet, useLoaderData } from "react-router-dom"

import { IconButton, CssBaseline } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"

import {
	NavBar as Nav,
	MenuBurger,
	WithHeaderAndFooter,
	useWithHeader,
	ScrollToTopAuto,
	LoadingCircle,
} from "components"
import myTheme from "themes/global"
import roundButtonTheme from "themes/roundButton"

import { getPicklists, picklistContext } from "backend/Picklist"
import { TPicklists } from "backend/Picklist/types"
import {
	getRatingScales,
	TRatingScales,
	ratingScaleContext,
} from "backend/RatingScales"
import { SearchProvider } from "hooks/useSearchContext"
import { TJobPosting, getAllJobPosting } from "backend/JobPosting"
import { jobPostingsContext } from "backend/JobPosting"
import { DeferredData } from "@remix-run/router/dist/utils"

type TLoaderData = {
	picklists: Promise<TPicklists>
	ratingScales: Promise<TRatingScales>
	jobPostings: Promise<TJobPosting[]>
}

type TAwaitedData = [
	Awaited<TLoaderData["picklists"]>,
	Awaited<TLoaderData["ratingScales"]>,
	Awaited<TLoaderData["jobPostings"]>
]

/**
 * This loader load every data the user will need during his navigation, in consequence there will be no loading time between pages
 *
 * @returns Promise containing all data we want to store in different react contexts
 */
const loader = async (): Promise<DeferredData> => {
	const picklists = getPicklists()
	const ratingScales = getRatingScales()
	const jobPostings = getAllJobPosting()

	return defer({
		picklists,
		ratingScales,
		jobPostings,
	})
}

const WithHeaderAndFooterContent = () => {
	const [drawerOpen, setDrawerOpen] = React.useState(false)
	useWithHeader(<Nav drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />)

	return (
		<React.Fragment>
			<ThemeProvider theme={roundButtonTheme}>
				<IconButton
					color="primary"
					onClick={() => setDrawerOpen((s) => !s)}
					sx={{
						display: { xs: "flex", md: "none" },
						position: "fixed",
						right: 20,
						top: 10,
						zIndex: 1300,
					}}
				>
					<MenuBurger crossed={drawerOpen} />
				</IconButton>
			</ThemeProvider>
			<Outlet />
		</React.Fragment>
	)
}

function Root() {
	const deferredData = useLoaderData() as TLoaderData

	return (
		<ThemeProvider theme={myTheme}>
			<CssBaseline />
			<ScrollToTopAuto />
			<React.Suspense fallback={<LoadingCircle />}>
				<Await
					resolve={Promise.all([
						deferredData.picklists,
						deferredData.ratingScales,
						deferredData.jobPostings,
					])}
				>
					{([picklists, ratingScales, jobPostings]: TAwaitedData) => (
						<picklistContext.Provider value={picklists}>
							<ratingScaleContext.Provider value={ratingScales}>
								<jobPostingsContext.Provider value={jobPostings}>
									<SearchProvider>
										<WithHeaderAndFooter>
											<WithHeaderAndFooterContent />
										</WithHeaderAndFooter>
									</SearchProvider>
								</jobPostingsContext.Provider>
							</ratingScaleContext.Provider>
						</picklistContext.Provider>
					)}
				</Await>
			</React.Suspense>
		</ThemeProvider>
	)
}

export { loader }
export default Root
