/**
 * @module SpontaneousApplication
 * This module provides a React functional component that displays a section encouraging users to send their CVs
 */
import { useNavigate } from "react-router-dom"

import { Box, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import { Accent, RollButton } from "components"

/**
 * SpontaneousApplication component
 * @returns {JSX.Element} Returns a section encouraging users to send their CVs
 */
const SpontaneousApplication = () => {
	const theme = useTheme()
	const navigate = useNavigate()
	return (
		<Box
			component="article"
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				rowGap: theme.spacing(3),
				flexBasis: 425,
				flexGrow: 1,
				paddingY: "3em",
				paddingLeft: { xs: "2em", lg: 0 },
				paddingRight: { xs: "2em", lg: "5em" },
				color: theme.palette.secondary.light,
			}}
		>
			<Typography
				variant="h2"
				textAlign={"center"}
				sx={{
					fontWeight: "bold",
				}}
			>
				<Accent
					sx={{
						fontWeight: "bold",
					}}
				>
					Envoyez-nous
				</Accent>{" "}
				votre CV !
			</Typography>
			<Typography textAlign={"center"}>
				Pour un job ou pour un stage en ingénierie informatique, rejoignez une
				SSII ambitieuse à taille humaine. Aucune offre SOLUTEC ne vous
				correspond ? Envoyez-nous votre candidature spontanée (un CV nous suffit
				!).
			</Typography>
			<RollButton
				variant="contained"
				sx={{
					alignSelf: "middle",
					height: "56px",
					marginX: "auto",
					width: "50%",
				}}
				onClick={() => navigate("/apply")}
			>
				Envoyer un CV
			</RollButton>
		</Box>
	)
}

export default SpontaneousApplication
