import * as React from "react"

import {
	Typography,
	Box,
	SvgIcon,
	Button,
	ButtonGroup,
	Grid,
	useTheme,
} from "@mui/material"
import UndoIcon from "@mui/icons-material/Undo"
import ShareButton from "components/StyleComponent/ShareButton"
import {
	Link,
	useNavigate,
	Await,
	useAsyncValue,
	useParams,
	useSearchParams,
} from "react-router-dom"

import { usePicklists } from "backend/Picklist"
import { TJobPosting, getJobPosting, useJobPostings } from "backend/JobPosting"

import {
	MainBox,
	RollButton,
	LoadingCircle,
	BackgroundHeader,
} from "components"

import customSanitize from "utils/customSanitize"

import background from "assets/bg_primary.jpeg"
import { UrlInvalidError } from "utils/errors"

type TURLParams = {
	id?: string
}

/**
 * A function to format the salary values from broadbean to be displayed
 * It removes the 'EUR' at the start and add '€' at the end
 *
 * @param salaryFrom the lower salary bound value from Broadbean
 * @param salaryTo the higher salary bound value from Broadbean
 * @param hideSalary whether the salary should be displayed or a placeholder text
 * @returns the salary range to be displayed or a placeHolder text if hideSalary is true
 */
const formatSalary = (
	salaryFrom: string | undefined,
	salaryTo: string | undefined,
	hideSalary: boolean
): string => {
	if (hideSalary || salaryFrom === undefined || salaryTo === undefined)
		return "À définir"
	let salary = ""
	let isFormated = false
	if (salaryFrom.startsWith("EUR")) {
		const newSalaryFrom = salaryFrom.replace("EUR", "")
		salary = isNaN(+newSalaryFrom)
			? newSalaryFrom
			: (+newSalaryFrom).toLocaleString()
		salary += "-"
		isFormated = true
	} else if (salaryFrom.trim() !== "") {
		salary = salaryFrom + "-"
	}
	if (salaryTo.startsWith("EUR")) {
		const newSalaryTo = salaryTo.replace("EUR", "")
		salary += isNaN(+newSalaryTo)
			? newSalaryTo
			: (+newSalaryTo).toLocaleString()
		isFormated = true
	} else if (salaryTo.trim() !== "") {
		salary += salaryTo
	}
	salary += isFormated ? "€" : ""
	return salary
}

/**
 * This component is currently not very useful, but it can be upgraded if ever you wan't to use deferred data specific to this component
 * Currently the data is loaded once whenever you access the website, thx to root component
 *
 * @returns React fragment containing ux loading logic
 */
const JobPosting = () => {
	const jobPostings = useJobPostings()

	const { id } = useParams<TURLParams>()
	if (id === undefined || isNaN(+id))
		throw new UrlInvalidError(id + " does not exist")
	const jobPosting = jobPostings?.find((job) => job.id === +id)
	let jobPostingPromise
	if (jobPosting === undefined) {
		jobPostingPromise = getJobPosting(+id)
	} else {
		jobPostingPromise = Promise.resolve(jobPosting)
	}

	return (
		<React.Suspense fallback={<LoadingCircle />}>
			<Await resolve={jobPostingPromise}>
				<JobPostingContent />
			</Await>
		</React.Suspense>
	)
}

const JobPostingContent = () => {
	const jobPosting = useAsyncValue() as TJobPosting

	const navigate = useNavigate()

	const theme = useTheme()

	const [searchParams] = useSearchParams()

	const picklists = usePicklists()

	const jobDescription = customSanitize({
		html: jobPosting.jobDescription,
	})

	const companyProfile = customSanitize({
		html: jobPosting.companyProfile,
	})

	const candidateProfile = customSanitize({
		html: jobPosting.candidateProfile,
	})

	const location = picklists.location.find((x) => x.id === jobPosting.location)
	const jobType = picklists.contract.find((x) => x.id === jobPosting.jobType)
	const sector = picklists.jobFamily.find((x) => x.id === jobPosting.sector)

	const source = searchParams.get("source") ?? "SolutecOffer"

	const handlePost = () => {
		const uri = `/apply/${jobPosting.location}/${jobPosting.jobFamily}?source=${source}`
		navigate(uri)
	}
	return (
		<React.Fragment>
			<BackgroundHeader backgroundPath={background} height="40vh">
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						height: "100%",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							alignSelf: "center",
							color: theme.palette.secondary.light,
						}}
					>
						<Typography variant="h2" sx={{ fontWeight: "bold" }}>
							{jobPosting.jobTitle}
						</Typography>
						<Typography variant="h3">{location?.label}</Typography>
						<Typography variant="h4">{jobType?.label}</Typography>
					</Box>
				</Box>
			</BackgroundHeader>
			<MainBox
				variant="column"
				sx={{ backgroundColor: theme.palette.background.paper }}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: { xs: "column", md: "row" },
						justifyContent: { md: "space-between" },
						width: "100%",
					}}
				>
					<Box
						sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
					>
						<Link to="/" style={{ textDecoration: "none" }}>
							<Button sx={{ gap: "10px" }}>
								<SvgIcon component={UndoIcon} />
								<Typography>Retour</Typography>
							</Button>
						</Link>
					</Box>
					<ButtonGroup sx={{ float: "inline-end" }}>
						<ShareButton></ShareButton>

						<RollButton
							type="button"
							variant="contained"
							color="primary"
							onClick={handlePost}
							sx={{ height: "56px" }}
						>
							Postuler
						</RollButton>
					</ButtonGroup>
				</Box>
				<Box
					component={"article"}
					sx={{
						display: "flex",
						flexDirection: "column",
						rowGap: "0.25em",
					}}
				>
					<Typography variant="h2" sx={{ marginBottom: "0.75em" }}>
						{jobPosting.jobTitle}
					</Typography>
					<Typography variant="h3" sx={{ marginBottom: "0.75em" }}>
						Description du poste
					</Typography>
					<Typography
						variant="body1"
						dangerouslySetInnerHTML={{ __html: companyProfile }}
					></Typography>
					<Typography
						variant="body1"
						dangerouslySetInnerHTML={{ __html: jobDescription }}
					></Typography>
					<Typography
						variant="body1"
						dangerouslySetInnerHTML={{ __html: candidateProfile }}
					></Typography>
				</Box>
				<Grid container columnSpacing={12} sx={{ marginTop: "2rem" }}>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={3}
						xl={3}
						sx={{ paddingBottom: "1em" }}
					>
						<Typography variant="h6">Disponibilité</Typography>
						<Typography variant="body1">{jobPosting.jobStartdate}</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={3}
						xl={3}
						sx={{ paddingBottom: "1em" }}
					>
						<Typography variant="h6">Expérience</Typography>
						<Typography variant="body1">{jobPosting.experience}</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={3}
						xl={3}
						sx={{ paddingBottom: "1em" }}
					>
						<Typography variant="h6">Rémunération</Typography>
						<Typography variant="body1">
							{formatSalary(
								jobPosting.salaryFrom,
								jobPosting.salaryTo,
								jobPosting.hideSalary
							)}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={3}
						xl={3}
						sx={{ paddingBottom: "1em" }}
					>
						<Typography variant="h6">Domaine d&#39;activité</Typography>
						<Typography variant="body1">{sector?.label}</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={3}
						xl={3}
						sx={{ paddingBottom: "1em" }}
					>
						<Typography variant="h6">Lieu</Typography>
						<Typography variant="body1">{location?.label}</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={3}
						xl={3}
						sx={{ paddingBottom: "1em" }}
					>
						<Typography variant="h6">Type de contrat</Typography>
						<Typography variant="body1">{jobType?.label}</Typography>
					</Grid>
				</Grid>
			</MainBox>
		</React.Fragment>
	)
}

export default JobPosting
