/**
 * An input component rendering a radio group and a slider in sync to input rating
 * @module
 */

import * as React from "react"
import {
	Slider,
	SliderProps,
	RadioGroup,
	FormControlLabel,
	FormControl,
	FormControlProps,
	Radio,
	FormLabel,
	Box,
	FormHelperText,
} from "@mui/material"
import { useSchemaLabel, useSchemaRequired } from "./SchemaContext"
import { useField } from "formik"
import ErrorHelperText from "./ErrorHelperText"
import { useDisableForm } from "./DisableForm"

type TRatingFieldProps = {
	/**
	 * The rating scale from which to generate options.
	 *
	 * Rating scales values that can be parsed a simple decimal
	 * values will be added to the slider.
	 * They are check against the following regex: `^\d*((,|\.)\d|\d)\d*$`
	 *
	 * Other values will be added to the radio group
	 */
	ratingScale: string[]
	/**
	 * The name of the formikField as defined in the schema
	 *
	 * Used to connect to yup and formik and retreive label, errors, required prop ...
	 */
	name: string
	label?: string | undefined
}

/**
 * Get the translation of a ratingScale value to a displayable string.
 * TODO : Move this translations to the php with their values
 *
 * @param s a rating scale value
 * @returns a translation of the value if it exist or the original value otherwise
 */
const convertValueToLabel = (s: string): string => {
	const labels: Record<string, string> = {
		"Not evaluated": "Non évalué",
	}
	if (s in labels) {
		return labels[s]
	} else {
		return s
	}
}

const isNumericRating = (s: string): boolean => {
	const numberRegex = /^\d*((,|\.)\d|\d)\d*$/
	return numberRegex.test(s)
}

/**
 * @param scale a rating scale
 * @returns Two arrays distinguishing numeric scale options from textual ones
 */
const processScale = (
	scale: string[]
): { radio: string[]; slider: number[] } => {
	const radio: string[] = []
	const slider: number[] = []
	for (const option of scale) {
		if (isNumericRating(option)) {
			slider.push(parseFloat(option))
		} else {
			radio.push(option)
		}
	}
	return {
		radio,
		slider,
	}
}

const RatingSlider = (
	props: Omit<SliderProps, "marks"> & {
		marks: number[]
		value: number | undefined
		setValue: (v: number) => void
	}
) => {
	const {
		marks: _m,
		value: _v,
		disabled: _d,
		setValue: _sv,
		...otherProps
	} = props
	const marks = props.marks.map((m) => ({
		value: m,
		label: m.toString(),
	}))
	const disabledLook = props.disabled || props.value === undefined
	return (
		<Slider
			sx={{
				width: props.marks.length * 60,
			}}
			slotProps={{
				root: { style: { color: disabledLook ? "#bdbdbd" : undefined } },
			}}
			onChange={(_e, v) => props.setValue(v as number)}
			value={props.value ?? marks[0].value}
			step={null}
			marks={marks}
			min={Math.min.apply(null, props.marks)}
			max={Math.max.apply(null, props.marks)}
			disabled={props.disabled}
			{...otherProps}
		/>
	)
}

const RatingRadioGroup = (
	props: FormControlProps & {
		options: string[]
		value: string | undefined
		setValue: (v: string) => void
	}
) => {
	const { options, value, disabled, setValue, ...otherProps } = props
	const disabledLook = disabled || value === undefined
	return (
		<FormControl {...otherProps} sx={{ flexGrow: 0 }}>
			<RadioGroup value={value ?? ""} onChange={(_e, v) => setValue(v)}>
				{options.map((opt) => (
					<FormControlLabel
						key={opt}
						value={opt}
						control={
							<Radio className={disabledLook ? "Mui-disabled" : undefined} />
						}
						label={convertValueToLabel(opt)}
						disabled={disabled}
					/>
				))}
			</RadioGroup>
		</FormControl>
	)
}

/**
 * An input component rendering a radio group and a slider in sync to input rating
 *
 * ##TODO
 * This component is currently bugged. The slider doesn't snap on non integer values
 * @group Components
 */
const RatingField = (props: TRatingFieldProps) => {
	const scale = processScale(props.ratingScale)
	const schemaLabel = useSchemaLabel(props.name)
	const schemaRequired = useSchemaRequired(props.name)
	const formDisabled = useDisableForm()
	const [formikField, formikMeta, formikHelpers] = useField<string>(props.name)
	const isErrorState = formikMeta.error !== undefined && formikMeta.touched
	const helperText = isErrorState ? (
		<ErrorHelperText>{formikMeta.error}</ErrorHelperText>
	) : (
		<React.Fragment />
	)
	return (
		<FormControl error={isErrorState} disabled={formDisabled}>
			<FormLabel required={schemaRequired}>
				{props.label ?? schemaLabel}
			</FormLabel>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					columnGap: 2,
					justifyContent: "flex-start",
					flexWrap: "wrap",
					paddingBottom: 3,
				}}
			>
				<RatingRadioGroup
					options={scale.radio}
					value={
						isNumericRating(formikField.value) ? undefined : formikField.value
					}
					setValue={formikHelpers.setValue}
					disabled={formDisabled}
				/>
				<RatingSlider
					marks={scale.slider}
					value={
						isNumericRating(formikField.value)
							? parseFloat(formikField.value)
							: undefined
					}
					setValue={(v) => formikHelpers.setValue(v.toString())}
					disabled={formDisabled}
				/>
			</Box>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	)
}

export default RatingField
export type { TRatingFieldProps }
