/**
 * @module ScrollToTopAuto
 * This module provides a React functional component that automatically scrolls to the top of the page whenever the route changes
 */
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

/**
 * ScrollToTopAuto component
 * @returns Returns null as this component doesn't render any JSX elements. Its sole purpose is to trigger side effects.
 */
const ScrollToTopAuto = () => {
	const { pathname } = useLocation()

	/**
	 * Using useEffect to scroll to the top of the page whenever the pathname changes
	 */
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	return null
}

export default ScrollToTopAuto
