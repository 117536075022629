/**
 * Utility function to work with css properties
 *
 * @module
 */

/**
 * Value type used for custom components
 *
 * Not a value type for any css property (that I know of).
 * Used as a property type in {@link components/RollButton!default | `<RollButton>`}
 */
type direction = "left" | "right" | "up" | "down"

/**
 * Properties associated to the
 * [`position`](https://developer.mozilla.org/en-US/docs/Web/CSS/position) property
 */
type side = "left" | "right" | "top" | "bottom"

/**
 * Value type used for custom components
 *
 * Not a value type for any css property (that I know of).
 */
type axis = "vertical" | "horizontal"

/**
 * Value type of the
 * [`flex-direction`](https://developer.mozilla.org/en-US/docs/Web/CSS/flex-direction)
 * property
 */
type layout = "column" | "row"

/**
 * Size properties
 */
type size = "height" | "width"

/**
 * Converts a {@link direction} or {@link side} to a {@link direction}
 *
 * @param x - A direction or a side
 * @returns `x` if it was already a {@link direction}
 * the {@link direction} corresponding to `x` if it was a {@link side}
 */
function Direction(x: direction | side): direction {
	switch (x) {
		case "left":
			return "left"
		case "right":
			return "right"
		case "down":
			return "down"
		case "up":
			return "up"
		case "bottom":
			return "down"
		case "top":
			return "up"
	}
}

/**
 * Converts a {@link direction} or {@link side} to a {@link side}
 *
 * @param x - A direction or a side
 * @returns `x` if it was already a {@link side}
 * the {@link side} corresponding to `x` if it was a {@link direction}
 */
function Side(x: direction | side): side {
	switch (x) {
		case "left":
			return "left"
		case "right":
			return "right"
		case "down":
			return "bottom"
		case "up":
			return "top"
		case "bottom":
			return "bottom"
		case "top":
			return "top"
	}
}

/**
 * Converts any coord type to an {@link axis}
 *
 * @param x - Any coord value
 * @returns The corresponding {@link axis}
 */
function Axis(x: direction | side | axis | layout | size): axis {
	switch (x) {
		case "left":
			return "horizontal"
		case "right":
			return "horizontal"
		case "up":
			return "vertical"
		case "down":
			return "vertical"
		case "top":
			return "vertical"
		case "bottom":
			return "vertical"
		case "vertical":
			return "vertical"
		case "horizontal":
			return "horizontal"
		case "column":
			return "vertical"
		case "row":
			return "horizontal"
		case "width":
			return "horizontal"
		case "height":
			return "vertical"
	}
}

/**
 * Converts any coord type to an {@link layout}
 *
 * @param x - Any coord value
 * @returns The corresponding {@link layout}
 */
function Layout(x: direction | side | axis | layout | size): layout {
	switch (x) {
		case "left":
			return "row"
		case "right":
			return "row"
		case "up":
			return "column"
		case "down":
			return "column"
		case "top":
			return "column"
		case "bottom":
			return "column"
		case "vertical":
			return "column"
		case "horizontal":
			return "row"
		case "column":
			return "column"
		case "row":
			return "row"
		case "width":
			return "row"
		case "height":
			return "column"
	}
}

/**
 * Converts any coord type to an {@link size}
 *
 * @param x - Any coord value
 * @returns The corresponding {@link size}
 */
function Size(x: direction | side | axis | layout | size): size {
	switch (x) {
		case "left":
			return "width"
		case "right":
			return "width"
		case "up":
			return "height"
		case "down":
			return "height"
		case "top":
			return "height"
		case "bottom":
			return "height"
		case "vertical":
			return "height"
		case "horizontal":
			return "width"
		case "column":
			return "height"
		case "row":
			return "width"
		case "width":
			return "width"
		case "height":
			return "height"
	}
}

/**
 * Get the conventional start {@link side} of an
 * {@link axis} or {@link layout} in the european
 * reading direction (top to bottom, left to right)
 *
 * @param x - An {@link axis} or {@link layout}
 * @returns `top` if `x` is `"vertical"` or `"column"`.
 * `left` if `x` is `"horizontal"` or `"row"`.
 */
function start(x: axis | layout): side {
	switch (x) {
		case "vertical":
			return "top"
		case "horizontal":
			return "left"
		case "row":
			return "left"
		case "column":
			return "top"
	}
}

/**
 * Get the conventional end {@link side} of an
 * {@link axis} or {@link layout} in the european
 * reading direction (top to bottom, left to right)
 *
 * @param x - An {@link axis} or {@link layout}
 * @returns `bottom` if `x` is `"vertical"` or `"column"`.
 * `right` if `x` is `"horizontal"` or `"row"`.
 */
function end(x: axis | layout): side {
	switch (x) {
		case "vertical":
			return "bottom"
		case "horizontal":
			return "right"
		case "row":
			return "right"
		case "column":
			return "bottom"
	}
}

/* The following rule is necessary because of a bug in eslint. Overloading a function shouldn't raise a linting error.
see : https://github.com/gajus/eslint-plugin-flowtype/issues/374 */
/* eslint-disable no-redeclare */
function cross(x: axis): axis
function cross(x: layout): layout
function cross(x: size): size
function cross(x: axis | layout | size): axis | layout | size {
	/* eslint-enable no-redeclare */
	switch (x) {
		case "vertical":
			return "horizontal"
		case "horizontal":
			return "vertical"
		case "row":
			return "column"
		case "column":
			return "row"
		case "width":
			return "height"
		case "height":
			return "width"
	}
}

/* The following rule is necessary because of a bug in eslint. Overloading a function shouldn't raise a linting error.
see : https://github.com/gajus/eslint-plugin-flowtype/issues/374 */
/* eslint-disable no-redeclare */
function opposite(d: direction): direction
function opposite(d: side): side
function opposite(d: direction | side): direction | side {
	/* eslint-enable no-redeclare */
	switch (d) {
		case "left":
			return "right"
		case "right":
			return "left"
		case "up":
			return "down"
		case "down":
			return "up"
		case "top":
			return "bottom"
		case "bottom":
			return "top"
	}
}

export { Direction, Side, Axis, Layout, Size, start, end, cross, opposite }
export type { direction, side, axis, layout, size }
