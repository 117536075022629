import { object, string, number, lazy, ObjectSchema } from "yup"
import { TServerError } from "./types"

/**
 * The [yup](https://github.com/jquense/yup) used to validate server errors
 *
 * @remark
 * Typscript ensures that the schema validates
 * an object of type {@link TServerError}
 */
const schema: ObjectSchema<TServerError> = object().shape({
	message: string().required(),
	previous: lazy(() => schema.default(null).defined().nullable()),
	code: number().integer().min(0).required(),
	file: string().required(),
	line: number().integer().positive().required(),
})

export default schema
