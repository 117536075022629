import * as React from "react"

import { defer, useLoaderData, Await } from "react-router-dom"

import { ThemeProvider, Typography } from "@mui/material"
import { FormikHelpers } from "formik"

import {
	MainBox,
	Fieldset,
	Form,
	TextField,
	PicklistField,
	useNotificationFeed,
	Accent,
	SyncSuccessAlert,
} from "components"
import { getSchema, TDataForm, TDataGet } from "backend/InterviewReport"
import { genFormInit } from "utils/form"
import { TPicklists, usePicklists } from "backend/Picklist"
import { TRatingScales, useRatingScales } from "backend/RatingScales"
import { getInterview, updateInterview } from "backend/InterviewReport/queries"
import RatingField from "components/Form/RatingField"
import useServerErrorAlert from "components/Form/ServerErrorAlert"
import FormTitle from "components/Form/FormTitle"
import { isServerError, schema as errorSchema } from "backend/ServerError"
import formTheme from "themes/form"
import { DeferredData } from "@remix-run/router/dist/utils"

type TURLParams = {
	applicationId?: string
	key?: string
	permission?: string
	interviewNumber?: string
}

type TLoaderData = {
	applicationId: number
	key: string
	interviewNumber: number
	existingData: Promise<TDataGet>
	permission: "view" | "modify"
}

type TAwaitedData = Awaited<TLoaderData["existingData"]>

const loader = async ({
	params,
}: {
	params: TURLParams
}): Promise<DeferredData> => {
	if (
		params.applicationId === undefined ||
		params.key === undefined ||
		!(params.interviewNumber === "1" || params.interviewNumber === "2")
	) {
		throw new Error()
	}
	const applicationId = parseInt(params.applicationId)
	const interviewNumber = parseInt(params.interviewNumber)
	return defer({
		applicationId: applicationId,
		key: params.key,
		interviewNumber: interviewNumber,
		existingData: getInterview(applicationId, params.key, interviewNumber),
		permission: params.permission as "view" | "modify",
	})
}

type TMetadata = {
	picklists: TPicklists
	ratingScales: TRatingScales
	interviewNumber: number
}

const FormContent = (props: { metadata: TMetadata }) => {
	const followUpPicklist =
		props.metadata.interviewNumber === 1
			? props.metadata.picklists.interview1FollowUp
			: props.metadata.picklists.interview2FollowUp
	const bs = props.metadata.ratingScales.baseScale
	return (
		<React.Fragment>
			<Fieldset>
				<Typography variant="h2">Formation</Typography>
				<TextField name="diploma" />
				<TextField name="diplomaYear" type="number" />
				<TextField name="diplomaMention" />
				<TextField name="training" multiline />
				<TextField name="experience" multiline />
			</Fieldset>
			<Fieldset>
				<Typography variant="h2">Evaluation</Typography>
				<RatingField ratingScale={bs} name="relationalRating" />
				<RatingField ratingScale={bs} name="evolutionPotentialRating" />
				<RatingField ratingScale={bs} name="experiencedescriptionRating" />
				<RatingField ratingScale={bs} name="elocutionRating" />
				<RatingField ratingScale={bs} name="flexibilityRating" />
				<RatingField ratingScale={bs} name="interestRating" />
				<RatingField ratingScale={bs} name="appearanceRating" />
				<RatingField ratingScale={bs} name="travelFlexibilityRating" />
				<TextField name="ratingComments" multiline />
			</Fieldset>
			<Fieldset>
				<Typography variant="h2">Langues Etrangères</Typography>
				<RatingField
					ratingScale={props.metadata.ratingScales.optionalScale}
					name="englishLevel"
				/>
				<TextField name="languageSkillsComment" multiline />
			</Fieldset>
			<Fieldset>
				<Typography variant="h2">Recherches</Typography>
				<TextField name="position" multiline />
				<TextField name="otherApplications" multiline />
				<TextField name="criterion1" />
				<TextField name="criterion2" />
				<TextField name="criterion3" />
				<TextField name="desiredActivityOtherComments" multiline />
			</Fieldset>
			<Fieldset>
				<Typography variant="h2">Disponibilité</Typography>
				<TextField name="dateOfDecision" type="date" />
				<TextField name="dateOfAvailability" type="date" />
				<TextField name="availabilityComments" multiline />
			</Fieldset>
			<Fieldset>
				<Typography variant="h2">Salaire</Typography>
				<TextField name="currentSalary" type="number" />
				<TextField name="desiredSalary" type="number" />
				<PicklistField
					name="desiredSalaryAssessment"
					picklist={props.metadata.picklists.salaryAssessment}
				/>
				<TextField name="salaryComments" multiline />
			</Fieldset>
			<Fieldset>
				<Typography variant="h2">Test</Typography>
				<TextField name="algorithmicTestResult" type="number" />
				<TextField name="logicTestResult" type="number" />
			</Fieldset>
			<Fieldset>
				<Typography variant="h2">Suite à donner</Typography>
				<RatingField
					ratingScale={props.metadata.ratingScales.preciseScale}
					name="interviewRating"
				/>
				<TextField name="overallAssessment" multiline />
				<PicklistField name="followUp" picklist={followUpPicklist} />
			</Fieldset>
		</React.Fragment>
	)
}

function getInterviewInfo(existingData: TDataGet): string {
	let interviewInfo = "Entretien"
	if (existingData.interviewDate != null) {
		const interviewDate = new Date(existingData.interviewDate)
		interviewInfo += " le "
		interviewInfo += interviewDate.toLocaleDateString("fr-FR")
		interviewInfo += " à "
		interviewInfo += interviewDate.toLocaleTimeString("fr-FR")
	}
	if (existingData.interviewerName != null) {
		interviewInfo += " par "
		interviewInfo += existingData.interviewerName
	}
	if (existingData.candidateName != null) {
		interviewInfo += " avec "
		interviewInfo += existingData.candidateName
	}
	if (interviewInfo === "Entretien") {
		interviewInfo = "Informations d'entretien non disponible"
	}
	return interviewInfo
}

const Interview = () => {
	const loaderData = useLoaderData() as TLoaderData
	const picklists = usePicklists()
	const ratingScales = useRatingScales()
	const metadata = {
		picklists: picklists,
		ratingScales: ratingScales,
		interviewNumber: loaderData.interviewNumber,
	}
	const schema = getSchema(metadata)
	const handleSubmit = async (
		data: TDataForm,
		formikHelpers: FormikHelpers<TDataForm>
	) => {
		const response = await updateInterview(
			loaderData.applicationId,
			loaderData.key,
			loaderData.interviewNumber,
			schema.validateSync(data)
		)
		if (!response.ok) {
			const responseData = await response.json()
			if (!isServerError(responseData)) {
				throw new Error(errorSchema.validateSync(responseData).toString())
			}
			setError(responseData)
		} else {
			pushNotif(<SyncSuccessAlert />)
		}
		formikHelpers.setSubmitting(false)
	}

	const [ErrorAlert, setError] = useServerErrorAlert()
	const [NotificationFeed, pushNotif] = useNotificationFeed({
		timings: {
			slideInDuration: 500,
			fadeOutDelay: 2000,
			fadeOutDuration: 2000,
		},
	})

	return (
		<ThemeProvider theme={formTheme}>
			<ErrorAlert />
			<NotificationFeed />
			<Await resolve={loaderData.existingData}>
				{(existingData: TAwaitedData) => (
					<MainBox variant="column">
						<FormTitle lastModified={existingData.lastModified}>
							Synthèse du{" "}
							<Accent>
								{loaderData.interviewNumber === 1 ? "premier" : "deuxième"}{" "}
								entretien
							</Accent>
						</FormTitle>
						<Typography variant="h3">
							{getInterviewInfo(existingData)}
						</Typography>
						<Form
							schema={schema}
							initialValues={genFormInit(schema, existingData)}
							onSubmit={handleSubmit}
							disabled={loaderData.permission === "view"}
						>
							<FormContent metadata={metadata} />
						</Form>
					</MainBox>
				)}
			</Await>
		</ThemeProvider>
	)
}

export default Interview
export { loader }
