/**
 * Render a spinning circle that with a background image that takes whole page
 *
 * used to make user wait
 *
 * @module
 */
import { Box, CircularProgress } from "@mui/material"
import BackgroundHeader from "./BackgroundHeader"
import background from "assets/bg_primary.jpeg"

const LoadingCircle = () => {
	return (
		<BackgroundHeader backgroundPath={background} height="100%">
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height={"100%"}
			>
				<CircularProgress size="10rem" />
			</Box>
		</BackgroundHeader>
	)
}

export default LoadingCircle
