/**
 * Usefull utils to clean element that is intended to be set as inner HTML
 * The purpose of this is to prevent XSS attacks
 *
 * Example : enter a <script>Kill everybody</script> in the inner HTML which will be executed
 *
 * See DOMPurify documentation for further details
 * https://www.npmjs.com/package/dompurify
 *
 * @module
 */
import DOMPurify from "dompurify"

const sanitizeOptions = {
	KEEP_CONTENT: true,
	ALLOWED_ATTR: ["style"],
	ALLOWED_TAGS: [
		"ul",
		"li",
		"h1",
		"h2",
		"h3",
		"h4",
		"h5",
		"h6",
		"h7",
		"p",
		"br",
		"hr",
		"font",
		"span",
		"strong",
		"em",
		"div",
	],
}

type TSanitizeContent = {
	html: string
}

const customSanitize = (props: TSanitizeContent) => {
	return DOMPurify.sanitize(props.html, sanitizeOptions)
}

export default customSanitize
