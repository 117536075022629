/**
 * An effect component making the page scroll to the first error
 * when a [formik](https://formik.org/) form submission fails
 * @module
 */
import * as React from "react"

import { useFormikContext } from "formik"

/**
 * An effect component making the page scroll to the first error
 * when a [formik](https://formik.org/) form submission fails
 *
 * This component does not render anything or accepts any prop
 *
 * @group Components
 */
const ScrollToError = () => {
	const formik = useFormikContext()
	const submitting = formik?.isSubmitting

	React.useEffect(() => {
		const el: HTMLElement | null = document.querySelector(
			".Mui-error, [data-error]"
		)
		const parentOrEl: HTMLElement | null = el?.parentElement ?? el
		parentOrEl?.scrollIntoView({ behavior: "smooth", block: "center" })
		el?.focus({ preventScroll: true })
	}, [submitting])
	return null
}

export default ScrollToError
