/**
 * Error boundary when an error is thrown in the loader or the component
 */

import React from "react"
import { isRouteErrorResponse, Link, useRouteError } from "react-router-dom"
import icon from "assets/icon_404.svg"
import { Typography, Box } from "@mui/material"
import Accent from "components/StyleComponent/Accent"
import MainBox from "./MainBox"
import { isServerError } from "backend/ServerError"
import { ServerError } from "components"
import {
	DeletedError,
	QueryError,
	QueryServerError,
	UrlInvalidError,
} from "utils/errors"

const ErrorPage: React.FC = () => {
	let title =
		"Erreur interne au serveur. Réessayez plus tard ou contactez un administrateur"
	let content = <React.Fragment></React.Fragment>

	const error = useRouteError()
	console.error(error)
	if (isRouteErrorResponse(error)) {
		if (isServerError(error.data.serverError)) {
			content = (
				<React.Fragment>
					<pre>
						{error.data.status} {error.data.statusText}
					</pre>
					<ServerError error={error.data.serverError} />
				</React.Fragment>
			)
		} else {
			content = (
				<pre style={{ width: "80vh", overflowX: "auto" }}>
					{JSON.stringify(error.data)}
				</pre>
			)
		}
	} else if (error instanceof UrlInvalidError) {
		title = "Page inexistante"
		content = <pre>URL invalide</pre>
	} else if (error instanceof DeletedError) {
		title = "Page supprimée"
		content = <pre>{error.message}</pre>
	} else if (error instanceof QueryServerError) {
		content = (
			<React.Fragment>
				<pre>
					{error.status} {error.statusText}
				</pre>
				<ServerError error={error.serverError} />
			</React.Fragment>
		)
	} else if (error instanceof QueryError) {
		content = (
			<pre style={{ width: "80vh", overflowX: "auto" }}>
				<React.Fragment>
					{error.status} {error.statusText} {"\n"}
					{error.message}
					{"\n\n"}
					{JSON.stringify(error.data)}
				</React.Fragment>
			</pre>
		)
	} else if (error instanceof Error) {
		content = (
			<pre style={{ width: "80vh", overflowX: "auto" }}>
				{error.message}
				{"\n\n"}
				{error.stack}
			</pre>
		)
	}

	return (
		<MainBox
			variant="column"
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					height: { xs: 89, md: 124 },
					width: { xs: 90, md: 125 },
				}}
			>
				<img style={{ height: "100%" }} src={icon} alt="404 icon"></img>
			</Box>
			<Typography variant="h2" sx={{ textAlign: "center" }}>
				<Accent>{title}</Accent>
			</Typography>
			{content}
			<Typography variant="h3">
				<Link to="/">Retourner à l&#39;accueil</Link>
			</Typography>
		</MainBox>
	)
}

export default ErrorPage
