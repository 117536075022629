/**
 * Combine multiple refs into one
 *
 * @module
 */

import * as React from "react"

/**
 * Combine multiple refs into one
 *
 * @param refs - multiple React refs to combine
 * @returns A combined ref `r` linked to the source refs such that
 * setting `r` sets all the source refs or call them with the value.
 *
 * @group Hooks
 */
const useCombinedRefs = <T>(
	...refs: React.ForwardedRef<T>[]
): React.MutableRefObject<T | null> => {
	const targetRef = React.useRef<T>(null)

	React.useEffect(() => {
		refs.forEach((ref) => {
			if (!ref) return
			if (typeof ref === "function") {
				if (targetRef.current !== undefined) ref(targetRef.current)
			} else {
				ref.current = targetRef.current ? targetRef.current : null
			}
		})
	}, [refs])

	return targetRef
}

export default useCombinedRefs
