/**
 * A custom form element wrapping
 * [yup](https://github.com/jquense/yup)
 * and
 * [formik](https://formik.org/) elements
 * @module
 */

import {
	Form as FormikForm,
	useFormikContext,
	FormikHelpers,
	Formik,
	FormikFormProps,
} from "formik"
import {
	ScrollToError,
	DisableForm,
	RollButton,
	SchemaProvider,
} from "components"
import { useTheme } from "@mui/material"
import { ObjectSchema } from "yup"
import { TForm } from "utils/form"

/**
 * The type of properties accepted by {@link Form | `<Form>`}
 *
 * Props from `FormikFormProps` are passed to the inner component.
 */
type TFormProps<V extends Record<string, unknown>> = FormikFormProps & {
	/** Disable every field in the form */
	disabled?: boolean | undefined
	/**
	 * The [yup](https://github.com/jquense/yup) schema passed to the
	 * [formik](https://formik.org/) [`<Form>`](https://formik.org/docs/api/form) component
	 * for validation
	 */
	schema: ObjectSchema<V>
	initialValues: TForm<V>
	onSubmit: (
		values: TForm<V>,
		formikHelpers: FormikHelpers<TForm<V>>
	) => void | Promise<any>
}

const SubmitButton = (props: { disabled: boolean }) => {
	const formikContext = useFormikContext()
	return (
		<RollButton
			variant="contained"
			type="submit"
			disabled={formikContext.isSubmitting || props.disabled}
			sx={{
				alignSelf: "flex-end",
			}}
		>
			Envoyer
		</RollButton>
	)
}

/**
 * A custom form element wrapping
 * [yup](https://github.com/jquense/yup)
 * and
 * [formik](https://formik.org/) elements
 *
 * See source code for more detailed explanation of its implementation
 * @group Components
 */
const Form = <V extends Record<string, unknown>>(props: TFormProps<V>) => {
	const { children, style, schema, initialValues, onSubmit, ...otherProps } =
		props
	const theme = useTheme()
	return (
		/**
		 * The [`<Formik>`](https://formik.org/docs/api/formik) component
		 * serves a context with form info and logic
		 * A [yup](https://github.com/jquense/yup) schema is passed for validation
		 */
		<Formik
			validationSchema={schema}
			initialValues={initialValues}
			onSubmit={onSubmit}
		>
			{/**
			 * The [`<Form>`](https://formik.org/docs/api/form) component from formik is
			 * a thin wrapper around the HTML `<form>`
			 * Only its styling is customized in this implementation
			 */}
			<FormikForm
				style={{
					display: "flex",
					flexDirection: "column",
					rowGap: theme.spacing(6),
					...style,
				}}
				{...otherProps}
			>
				{/**
				 * {@link DisableForm} is a context provider used by
				 * {@link Field | `<Field>`} components to set their disabled state
				 * It effectively disables the whole form if `prop.disabled` is true
				 */}
				<DisableForm value={props.disabled ?? false}>
					{/**
					 * {@link SchemaProvider} is a context provider used by
					 * {@link Field | `<Field>`} components to retreive
					 * error state, labels, and other field info attached to the schema
					 */}
					<SchemaProvider value={props.schema}>
						{/**
						 * {@link ScrollToError} is a small components rendering nothing
						 * but registering an event scrolling the page to the first error
						 * on submit attempts
						 */}
						<ScrollToError />
						{children}
						{/**
						 * {@link SubmitButton} is a simple {@link RollButton}
						 * disabling itself if the form is disabled or if submitting
						 * is in progress
						 */}
						<SubmitButton disabled={props.disabled ?? false} />
					</SchemaProvider>
				</DisableForm>
			</FormikForm>
		</Formik>
	)
}

export default Form
export type { TFormProps }
