/**
 * A button whose content rolls when hovered,
 * mimicking [solutec.fr](https://www.solutec.fr/fr/)
 *
 * @module
 */
import * as React from "react"

import { Box, Button, IconButton, ButtonProps, Slide } from "@mui/material"
import { SxProps } from "@mui/system"

import * as coord from "utils/coord"
import useCombinedRefs from "hooks/useCombinedRefs"

/**
 * {@link RollButton | `<RollButton>`} properties type
 */
type TRollButtonProps = {
	/** Set the direction in which the button content rolls when hovered */
	rollDirection?: coord.direction
	/**
	 * If true use [mui `<IconButton>`](https://mui.com/material-ui/react-button/#icon-button)
	 * instead of [`<Button>`](https://mui.com/material-ui/react-button/)
	 * */
	icon?: boolean
	/** The button content */
	children: React.ReactNode
} & ButtonProps

const RollButtonFC = (
	{
		rollDirection = "up",
		icon = false,
		children,
		onPointerEnter,
		onPointerLeave,
		sx,
		...others
	}: TRollButtonProps,
	ref: React.ForwardedRef<HTMLButtonElement>
) => {
	const [hovered, setHovered] = React.useState(false)
	const containerRef = React.useRef<HTMLButtonElement>(null)
	const combinedRef = useCombinedRefs<HTMLButtonElement>(containerRef, ref)
	const sxBoxHover: SxProps = {
		position: "absolute",
		top: 0,
		bottom: 0,
	}

	const buttonProps: ButtonProps = {
		sx: {
			display: "flex",
			overflow: "hidden",
			...sx,
		},
		ref: combinedRef,
		onPointerEnter: (e) => {
			if (onPointerEnter !== undefined) onPointerEnter(e)
			setHovered(true)
		},
		onPointerLeave: (e) => {
			if (onPointerLeave !== undefined) onPointerLeave(e)
			setHovered(false)
		},
		...others,
	}

	const buttonContent = (
		<Box sx={{ position: "relative" }}>
			<Box>
				<Slide
					appear={false}
					easing="ease"
					timeout={300}
					direction={coord.opposite(rollDirection)}
					in={!hovered}
					container={combinedRef.current}
				>
					<Box sx={{ display: "flex" }}>{children}</Box>
				</Slide>
			</Box>
			<Box sx={sxBoxHover}>
				<Slide
					appear={false}
					easing="ease"
					timeout={300}
					direction={rollDirection}
					in={hovered}
					container={combinedRef.current}
				>
					<Box sx={{ display: "flex" }}>{children}</Box>
				</Slide>
			</Box>
		</Box>
	)

	if (icon) {
		return <IconButton {...buttonProps}>{buttonContent}</IconButton>
	} else {
		return <Button {...buttonProps}>{buttonContent}</Button>
	}
}

/**
 * A button whose content rolls when hovered,
 * mimicking [solutec.fr](https://www.solutec.fr/fr/)
 *
 * use [`React.forwardRef`](https://react.dev/reference/react/forwardRef)
 * to forward its ref prop to the underlying DOM node
 *
 * @group Components
 */
const RollButton = React.forwardRef(RollButtonFC)

export default RollButton
export type { TRollButtonProps }
