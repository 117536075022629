/**
 * Object types and typguards representing picklists
 * @module
 */

/**
 * A picklist option as stored in SF
 */
type TPicklistOption = {
	/** The primary key of the picklist option */
	id: number
	/** The status of the picklist option
	 *
	 * ## TODO
	 * Should be a union type `'ACTIVE'|'OBSOLETE'|'DELETED'`
	 *
	 * Defined in [The Success Factor documentation](https://help.sap.com/docs/SAP_SUCCESSFACTORS_PLATFORM/d599f15995d348a1b45ba5603e2aba9b/7722a976be2a42f5a0269eae1a6686c4.html#properties-and-navigation-properties)
	 */
	status: string
	/**
	 * A short string label
	 *
	 * Either this field or `mdfExternalCode` are non empty
	 * depending on the way picklists were configured in SF
	 */
	externalCode: string
	/**
	 * A short string label
	 *
	 * Either this field or `mdfExternalCode` are non empty
	 * depending on the way picklists were configured in SF
	 */
	mdfExternalCode: string
	/**
	 * The full length label
	 *
	 * In success factor, multiple locals are configured for a picklist option label
	 * The backend only fetches and syncs the french locale label
	 */
	label: string
}

/**
 * A static list of picklists useful for the front end
 *
 * There are more picklists synced in the backend database.
 * Some of them are not useful right now but take a look at the Success Factor instance
 * and at the C# synchronisation service to see which picklist are
 * available for retreival in the frontend.
 */
type TPicklists = {
	location: TPicklistOption[]
	contract: TPicklistOption[]
	jobFamily: TPicklistOption[]
	custFunction: TPicklistOption[]
	salutation: TPicklistOption[]
	levelOfEducation: TPicklistOption[]
	applicationSource: TPicklistOption[]
	school: TPicklistOption[]
	languageLevel: TPicklistOption[]
	salaryAssessment: TPicklistOption[]
	interview1FollowUp: TPicklistOption[]
	interview2FollowUp: TPicklistOption[]
	country: TPicklistOption[]
}

/**
 * Checks if a picklist option id belongs to a picklist
 *
 * @param picklist - An array of picklist options
 * @param id - A picklist option id
 * @returns `true` if the picklist option identified by `id` belongs to `picklist`. `false` otherwise
 */
const isValidPLOption = (picklist: TPicklistOption[], id: number): boolean =>
	picklist.some((option) => option.id === id)

/**
 * Generate a "typeguard" for every picklist
 *
 * This function is useless. Please use {@link isValidPLOption} directly
 *
 * ## TODO
 * Replace every occurence of this function with a direct usage of {@link isValidPLOption}
 *
 * @param picklists - A collection of every picklist useful to the front end
 * @returns - A collection of functions checking if a picklist option belongs to some picklist
 * @deprecated
 */
const getPLValidator = (
	picklists: TPicklists
): { [K in keyof TPicklists]: (id: number) => boolean } => ({
	location: (id: number): boolean => isValidPLOption(picklists.location, id),
	contract: (id: number): boolean => isValidPLOption(picklists.contract, id),
	jobFamily: (id: number): boolean => isValidPLOption(picklists.jobFamily, id),
	custFunction: (id: number): boolean =>
		isValidPLOption(picklists.custFunction, id),
	salutation: (id: number): boolean =>
		isValidPLOption(picklists.salutation, id),
	levelOfEducation: (id: number): boolean =>
		isValidPLOption(picklists.levelOfEducation, id),
	applicationSource: (id: number): boolean =>
		isValidPLOption(picklists.applicationSource, id),
	school: (id: number): boolean => isValidPLOption(picklists.school, id),
	languageLevel: (id: number): boolean =>
		isValidPLOption(picklists.languageLevel, id),
	salaryAssessment: (id: number): boolean =>
		isValidPLOption(picklists.salaryAssessment, id),
	interview1FollowUp: (id: number): boolean =>
		isValidPLOption(picklists.interview1FollowUp, id),
	interview2FollowUp: (id: number): boolean =>
		isValidPLOption(picklists.interview2FollowUp, id),
	country: (id: number): boolean => isValidPLOption(picklists.country, id),
})

/**
 * Check if a picklist option is flagged as `OBSOLETE` in Success Factor
 *
 * ## TODO
 * Is this really what you want ? Maybe it should check for not obsoleted and not deleted ?
 *
 * @param plOption - A picklist option
 * @returns - `false` if `plOption` is Obsoleted. `true` otherwise
 */
const isNotObsolete = (plOption: TPicklistOption): boolean =>
	plOption.status !== "OBSOLETED"

export type { TPicklistOption, TPicklists }
export { isValidPLOption, getPLValidator, isNotObsolete }
