/**
 * The navigation bar to render at the top of every page
 *
 * ## TODO
 * - Load static links from a config file to make it easier to update
 * - Link social buttons to something or remove them
 *
 * @module
 */

import * as React from "react"

import {
	Typography,
	Toolbar,
	Box,
	AppBar,
	Drawer,
	useTheme,
	BoxProps,
	Button,
	Link,
	LinkProps,
	useMediaQuery,
} from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { Link as ReactLink, useNavigate, useLocation } from "react-router-dom"

import { BarButton, RollButton } from "components"
import roundButtonTheme from "themes/roundButton"
import logo from "assets/logo-solutec.svg"

const homeLink = "https://www.solutec.fr/fr/"
const externalpages = [
	{ name: "Entreprise", link: "https://www.solutec.fr/fr/entreprise/" },
	{ name: "Prestations", link: "https://www.solutec.fr/fr/prestations/" },
	{
		name: "Métiers",
		link: "https://www.solutec.fr/fr/developpement-applicatif/",
	},
	{ name: "Carrière", link: "https://www.solutec.fr/fr/carriere/" },
	{ name: "Contact", link: "https://www.solutec.fr/fr/contact/" },
	{ name: "Actualités", link: "https://www.solutec.fr/fr/actualites/" },
	{ name: "RSE", link: "https://www.solutec.fr/fr/rse/" },
]
const internalpages = [{ name: "Offres", to: "/" }]

const headerSizeBig = 130
const headerSizeSmall = 80

const ExternalLinks = ({ sx, ...others }: BoxProps) => (
	<Box
		component="section"
		{...others}
		sx={{
			display: "flex",
			whiteSpace: "nowrap",
			columnGap: 2,
			alignSelf: "flex-end",
			paddingY: "19px",
			...sx,
		}}
	>
		<ThemeProvider theme={roundButtonTheme}>
			<ReactLink
				to="https://www.linkedin.com/company/solutec/"
				style={{ textDecoration: "none" }}
			>
				<RollButton variant="contained" color="primary" sx={{ ...sx }}>
					in
				</RollButton>
			</ReactLink>
		</ThemeProvider>
	</Box>
)

const Logo = ({ sx, ...others }: LinkProps) => {
	// ratio of the logo image
	const logoRatio = 178 / 91
	const mdLogoWidth = 50
	const lgLogoWidth = 70

	return (
		<Link
			{...others}
			href={homeLink}
			sx={{
				height: {
					xs: 38,
					md: `${mdLogoWidth}%`,
					lg: `${lgLogoWidth}%`,
				},
				// we use headerSizeBig so width does not shrink even when header is small
				width: {
					md: (mdLogoWidth / 100) * headerSizeBig * logoRatio,
					lg: (lgLogoWidth / 100) * headerSizeBig * logoRatio,
				},
				mr: { xs: 0, md: 6, lg: 11 },
				display: "flex",
				justifyContent: "center",
				...sx,
			}}
		>
			<img
				style={{
					height: "100%",
					transition: "0.5s",
				}}
				src={logo}
				alt={""}
			/>
		</Link>
	)
}

const InternalLinks = ({ sx, ...others }: BoxProps) => {
	const navigate = useNavigate()
	const route = useLocation().pathname
	return (
		<Box
			component="section"
			{...others}
			sx={{
				display: { xs: "none", md: "flex" },
				height: 80,
				columnGap: {
					xl: 4.9,
					lg: 2,
					md: 1,
				},
				mr: { xs: 0, md: 6.4 },
				alignSelf: "flex-end",
				...sx,
			}}
		>
			{internalpages.map((page) => (
				<BarButton
					key={page.name}
					id={`nav-${page.name}`}
					className={route === page.to ? "selected" : ""}
					barSide="bottom"
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						px: "3px",
					}}
					onClick={() => navigate(page.to)}
				>
					<Typography variant="link">{page.name}</Typography>
				</BarButton>
			))}
			{externalpages.map((page) => (
				<BarButton
					key={page.name}
					href={page.link}
					barSide="bottom"
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						px: "3px",
					}}
				>
					<Typography variant="link">{page.name}</Typography>
				</BarButton>
			))}
		</Box>
	)
}

const InternalLinksDrawer = ({ sx, ...others }: BoxProps) => {
	const theme = useTheme()
	return (
		<Box
			component="section"
			sx={{
				display: "flex",
				flexDirection: "column",
				backgroundColor: theme.palette.secondary.light,
				...sx,
			}}
			{...others}
		>
			{internalpages.map((page) => (
				<ReactLink key={page.name} to={page.to}>
					<Button
						key={page.name}
						color="secondary"
						sx={{
							width: "100%",
							justifyContent: "start",
							px: 4,
							py: 3,
							letterSpacing: 0,
						}}
					>
						<Typography variant="link">{page.name}</Typography>
					</Button>
				</ReactLink>
			))}
			{externalpages.map((page) => (
				<Button
					key={page.name}
					href={page.link}
					color="secondary"
					sx={{
						width: "100%",
						justifyContent: "start",
						px: 4,
						py: 3,
						letterSpacing: 0,
					}}
				>
					<Typography variant="link">{page.name}</Typography>
				</Button>
			))}
		</Box>
	)
}

/**
 * {@link ResponsiveAppBar | `<Navbar>`} properties type
 */
type TResponsiveAppBarProps = {
	/** Only used on small screens. When true, the navigation menu renders */
	drawerOpen: boolean
	/** Only used on small screens. Sets the navigation menu opened or closed */
	setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

/**
 * The navigation bar to render at the top of every page
 *
 * Implemented to mimic [solutec.fr](https://www.solutec.fr/fr/).
 * Highly reponsive (cause Im too good)
 *
 * @group Components
 */
const ResponsiveAppBar = ({
	drawerOpen,
	setDrawerOpen,
}: TResponsiveAppBarProps) => {
	const theme = useTheme()

	const isUnderMd = useMediaQuery(theme.breakpoints.down("md"))
	const headerHeightOnTop = isUnderMd
		? `${headerSizeSmall}px`
		: `${headerSizeBig}px`
	const headerHeightWhenScrolling = `${headerSizeSmall}px`

	const [headerHeight, setHeaderHeight] = React.useState(headerHeightOnTop)

	React.useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY < 1) {
				setHeaderHeight(headerHeightOnTop)
			} else {
				setHeaderHeight(headerHeightWhenScrolling)
			}
		}

		// run it once every time isUnderMd changes
		handleScroll()

		window?.addEventListener("scroll", handleScroll)
		return () => {
			window?.removeEventListener("scroll", handleScroll)
		}
	}, [headerHeightOnTop, headerHeightWhenScrolling])

	return (
		<AppBar position="sticky" color="default" component="div">
			<Toolbar
				component="nav"
				disableGutters
				sx={{
					mx: "auto",
					px: { xs: 4, md: 0 },
					width: { xs: "100%", md: 860, lg: 1120, xl: 1190 },
					justifyContent: { xs: "space-between", md: "flex-start" },
					boxSizing: "border-box",
					height: headerHeight,
					transition: "height 0.3s",
				}}
			>
				<Drawer
					hideBackdrop
					open={drawerOpen}
					anchor="top"
					onClose={() => setDrawerOpen(false)}
				>
					<Box
						sx={{
							display: "flex",
							height: 60,
							justifyContent: "space-between",
							alignItems: "center",
							px: 4,
						}}
					>
						<ExternalLinks />
					</Box>
					<InternalLinksDrawer />
				</Drawer>
				<Logo />
				<InternalLinks
					sx={{
						display: { xs: "none", md: "flex" },
					}}
				/>
				<ExternalLinks
					sx={{
						display: { xs: "none", md: "flex" },
					}}
				/>
			</Toolbar>
		</AppBar>
	)
}

export default ResponsiveAppBar
export type { TResponsiveAppBarProps }
