/**
 * A custom text field connected to
 * [yup](https://github.com/jquense/yup) and
 * [formik](https://formik.org/)
 *
 * @module
 */

import * as React from "react"

import { useField } from "formik"
import {
	useSchemaLabel,
	useSchemaRequired,
	ErrorHelperText,
	useDisableForm,
} from "components"
import {
	TextField as MuiTextField,
	TextFieldProps as MuiTextFieldProps,
} from "@mui/material"

/**
 * {@link TextField | `<TextField>`} properties type
 *
 * Can take any prop accepted by
 * [`<TextField>` from mui](https://mui.com/material-ui/react-text-field/)
 * except the one controlled by formik.
 *
 * Properties are passed to the inner
 * [`<TextField>` from mui](https://mui.com/material-ui/react-text-field/)
 */
type TTextFieldProps = {
	/**
	 * The name of the field as described in the schema
	 *
	 * Used to connect to yup and formik and retreive label, errors, required status ...
	 */
	name: string
} & Omit<
	MuiTextFieldProps,
	"value" | "error" | "onChange" | "onBlur" | "helperText"
>

/**
 * A custom text field connected to
 * [yup](https://github.com/jquense/yup) and
 * [formik](https://formik.org/)
 *
 * Uses the contexts provided by {@link components/Form/SchemaContext}
 * and [formik](https://formik.org/) to retreive label, if the field is
 * required or not, error status and message, validation rule.
 *
 * Uses the context provided by {@link useDisableForm}
 * to disable itself if instructed to.
 *
 * @group Components
 */
const TextField = (props: TTextFieldProps) => {
	const [formikField, formikMeta] = useField<string>(props.name)
	const schemaLabel = useSchemaLabel(props.name)
	const schemaRequired = useSchemaRequired(props.name)
	const formDisabled = useDisableForm()
	const isErrorState = formikMeta.error !== undefined && formikMeta.touched
	const {
		label: _l,
		InputLabelProps: _ilp,
		disabled: _d,
		...nonControlledProps
	} = props
	const helperText = isErrorState ? (
		<ErrorHelperText>{formikMeta.error}</ErrorHelperText>
	) : (
		<React.Fragment />
	)
	const controlledProps: Partial<MuiTextFieldProps> = {
		error: isErrorState,
		helperText,
		label: props.label ?? schemaLabel,
		disabled: props.disabled ?? formDisabled,
		InputLabelProps: {
			required: schemaRequired,
			...props.InputLabelProps,
		},
		onChange: (e) => formikField.onChange(e),
		onBlur: (e) => formikField.onBlur(e),
		value: formikField.value,
	}
	return <MuiTextField {...controlledProps} {...nonControlledProps} />
}

export default TextField
export type { TTextFieldProps }
