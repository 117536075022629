/**
 * A [Box](https://mui.com/material-ui/react-box/) with styling
 * adapted to contain the whole body of a page
 *
 * @module
 */
import { Box, BoxProps } from "@mui/material"

/**
 * {@link MainBox | `<MainBox>`} properties type
 *
 * Pass all `BoxProps` to the underlying
 * [mui `<Box>`](https://mui.com/material-ui/react-box/)
 */
type TMainBoxProps = {
	/** Sets the main direction of the content. See implementation for details */
	variant: "rowWarp" | "column"
} & BoxProps

/**
 * A [Box](https://mui.com/material-ui/react-box/) styled
 * to contain the whole body of a page
 *
 * This component should be used at the root of each page
 * to set margins, spacing, etc.
 *
 * @group Components
 */
const MainBox = (props: TMainBoxProps) => {
	const { variant: _va, sx: _sx, children: _ch, ...others } = props
	return (
		<Box
			component="main"
			sx={{
				flexGrow: 1,
				display: "flex",
				flexDirection:
					props.variant === "rowWarp"
						? "row"
						: props.variant === "column"
						? "column"
						: "column",
				flexWrap:
					props.variant === "rowWarp"
						? "wrap"
						: props.variant === "column"
						? "nowrap"
						: "nowrap",
				px: {
					xs: 8,
					md: 12,
					xl: 16,
				},
				py: {
					xs: 8,
					md: 12,
					xl: 16,
				},
				mx: "auto",
				width: "100%",
				rowGap: 8,
				columnGap: 8,
				maxWidth:
					props.variant === "rowWarp"
						? undefined
						: props.variant === "column"
						? 1500
						: undefined,
				alignSelf:
					props.variant === "rowWarp"
						? undefined
						: props.variant === "column"
						? undefined
						: undefined,
				...props.sx,
			}}
			{...others}
		>
			{props.children}
		</Box>
	)
}

MainBox.defaultProps = {
	variant: "rowWarp",
}

export default MainBox
export type { TMainBoxProps }
