/**
 * ## TODO
 * Sending, receiving, and processing requests and responses should go through a client layer.
 * The client could do proper error handling
 *
 * @module
 */

import { intoBackendData } from "./schema"
import { TDataSchema } from "./types"

/**
 * Push a candidate application form data to the php backend
 *
 * Connection details should be delegated to a proper client.
 * See {@link backend/CandidateApplication/queries}
 *
 * @param form - Form data validated and cast by the schema (hence the type)
 * @returns A promise containing a response object
 */
const sendCandidateApplication = async (
	form: TDataSchema,
	fileId: number
): Promise<Response> => {
	const payload = intoBackendData(form, fileId)
	payload["fileId"] = fileId
	const request = {
		method: "POST",
		body: JSON.stringify(payload, (key, value) =>
			typeof value === "string" ? value.replaceAll("..\n", "..\u200B\n") : value
		),
		headers: {
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(
		process.env.REACT_APP_BACKEND_URL + "/CandidateApplication.php",
		request
	)
	return response
}

const sendResumeFile = async (file: File): Promise<Response> => {
	const formData = new FormData()
	formData.append("file", file)
	const request = {
		method: "POST",
		body: formData,
	}
	const response = await fetch(
		process.env.REACT_APP_BACKEND_URL + "/File.php",
		request
	)
	return response
}

export { sendCandidateApplication, sendResumeFile }
