/**
 * Custom [yup](https://github.com/jquense/yup) schemas
 * and locale configuration
 *
 * @module
 */
import { isValidPhoneNumber } from "libphonenumber-js/max"
import { number, string, setLocale, date as yupDate } from "yup"
import { fr } from "yup-locales"

/**
 * Set the locale for yup errors globally
 *
 * ## TODO
 * Improve error messages
 */
setLocale({
	...fr,
	boolean: {
		isValue: ({ path, value }) =>
			`${path} doit être ${value ? "coché" : "décoché"}`,
	},
})

/**
 * Custom yup validator : checks that the value is a valid enum option
 *
 * @param validator - A function that checks a number belongs to the desired enum option
 * @returns A yup schema ensuring the value is a valid enum option
 */
const enumOption = (validator: (id: number) => boolean) =>
	number()
		.integer()
		.test(
			"is-valid",
			"This is not a valid option",
			(value: number | undefined) =>
				value === undefined ? true : validator(value)
		)

const phoneNumberTestObj = {
	name: "is-E164Number",
	message: "Not a valid phone number",
	test: (value: string | undefined) => {
		if (value === undefined || value === "") {
			return true
		}
		return isValidPhoneNumber(value, "FR")
	},
	skipAbsent: true,
	exclusive: true,
}

/**
 * Custom yup validator : checks that the value is a valid phone number
 *
 * Uses libphoneNumber to check that the number is valid if composed from France.
 *
 * @returns A yup string schema ensuring the value is a valid phone number
 */
const phoneNumber = () => string().max(100).test(phoneNumberTestObj)

const parseDateString = (value: Date | string) => {
	let parsedDate
	if (value instanceof Date) {
		if (isNaN(value.getTime())) return undefined
		parsedDate = new Date(
			Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0)
		)
	} else {
		parsedDate = Date.parse(value)
	}

	return parsedDate
}

/**
 * Custom yup validator : checks that the value is a valid date
 *
 * Accepts a Date object or a string representing a date in any format accepted
 * by `Date.parse`.
 *
 * @returns A yup schema ensuring the value is a Date object or a string representing a date.
 * The schema returns a Date object.
 */
const date = () => yupDate().transform(parseDateString)

/**
 * Custom yup validator : the default number validator with an additional transform to
 * convert empty strings to `undefined`.
 *
 * When a numeric form input is left empty, it returns an empty string value.
 * The default number validator fails and produce a `NaN` value. This tends to produce a
 * strange and unreadable error message.
 *
 * This custom schema first casts any empty string value to `undefined` so that when left
 * empty, a numeric field produces a `This field is required`error message.
 *
 * @returns A yup schema ensuring the value is a number. With an additional transform casting
 * empty strings to `undefined`.
 */
const numberEmptyStringToUndefined = () =>
	number().transform((val, originalVal, _) =>
		originalVal === "" ? undefined : val
	)

export { enumOption, phoneNumber, date, numberEmptyStringToUndefined }
