import * as React from "react"
import { useLoaderData, redirect, Link, defer, Await } from "react-router-dom"

import { Typography, ThemeProvider } from "@mui/material"
import { FormikHelpers } from "formik"

import {
	MainBox,
	Accent,
	Fieldset,
	CardFieldset,
	FieldsetContainer,
	SyncSuccessAlert,
	useNotificationFeed,
	PicklistField,
	Form,
	FormTitle,
	TextField,
	CheckboxField,
} from "components"
import formTheme from "themes/form"
import {
	getSchema,
	TDataGet,
	TDataForm,
	getCandidateFile,
	updateCandidateFile,
} from "backend/CandidateFile"
import { genFormInit } from "utils/form"
import { usePicklists } from "backend/Picklist"
import { TPicklists } from "backend/Picklist/types"
import { isServerError, schema as errorSchema } from "backend/ServerError"
import useServerErrorAlert from "components/Form/ServerErrorAlert"
import { DeferredData } from "@remix-run/router/dist/utils"

type TURLParams = {
	candidateId?: string
	permission?: string
	key?: string
}

type TLoaderData = {
	candidateId: number
	key: string
	existingData: Promise<TDataGet>
	permission: "view" | "modify"
}

type TAwaitedData = Awaited<TLoaderData["existingData"]>

const loader = async ({
	params,
}: {
	params: TURLParams
}): Promise<DeferredData | Response> => {
	if (params.candidateId === undefined) {
		return redirect("/")
	}
	if (params.key === undefined) {
		return redirect("/")
	}
	if (params.permission !== "view" && params.permission !== "modify") {
		return redirect(`/CandidateFile/${params.candidateId}/view/${params.key}`)
	}
	const candidateId = parseInt(params.candidateId)
	return defer({
		candidateId: candidateId,
		key: params.key,
		permission: params.permission,
		existingData: getCandidateFile(candidateId, params.key),
	})
}

const FormContent = (props: { picklists: TPicklists }) => (
	<React.Fragment>
		<Fieldset>
			<Typography variant="h2">Etat Civil</Typography>
			<Fieldset horizontal>
				<PicklistField
					name="salutation"
					picklist={props.picklists.salutation}
				/>
				<TextField name="firstName" />
				<TextField name="lastName" />
			</Fieldset>
			<TextField name="residenceAdress" multiline />
			<Fieldset horizontal>
				<TextField name="residenceState" />
				<TextField name="residenceCity" />
			</Fieldset>
			<TextField name="contactEmail" type="email" />
			<TextField name="cellPhone" />
			<TextField name="dateOfBirth" type="date" />
			<TextField name="placeOfBirth" />
			<PicklistField name="countryOfBirth" picklist={props.picklists.country} />
			<TextField name="citizenship" />
		</Fieldset>
		<Fieldset>
			<Typography variant="h2">Baccalauréat</Typography>
			<Fieldset horizontal>
				<TextField name="bachelorsSpecialty" />
				<TextField name="bachelorsYear" type="number" />
				<TextField name="bachelorsMention" />
			</Fieldset>
		</Fieldset>
		<Fieldset>
			<Typography variant="h2">Formation</Typography>
			<PicklistField
				name="levelOfEducation"
				picklist={props.picklists.levelOfEducation}
			/>
			<TextField name="diploma" />
			<PicklistField
				name="school"
				picklist={props.picklists.school.sort((a, b) =>
					a.externalCode === "other"
						? -1
						: b.externalCode === "other"
						? 1
						: a.label.localeCompare(b.label)
				)}
			/>
			<TextField name="otherSchool" />
			<Fieldset horizontal>
				<TextField name="diplomaYear" type="number" />
				<TextField name="diplomaMention" />
			</Fieldset>
			<TextField name="otherDiplomas" multiline />
			<TextField name="certifications" multiline />
		</Fieldset>
		<Fieldset>
			<Typography variant="h2">Langues Etrangères</Typography>
			<PicklistField
				name="englishLevel"
				picklist={props.picklists.languageLevel}
			/>
			<TextField
				InputLabelProps={{ shrink: true }}
				name="englishTests"
				placeholder="Notes et années d'obtention"
				multiline
			/>
			<TextField name="otherLanguages" multiline />
		</Fieldset>
		<Fieldset>
			<Typography variant="h2">
				Emploi / Stage actuel (ou dernier emploi/stage)
			</Typography>
			<TextField name="employementInternshipCompany" />
			<Fieldset horizontal>
				<TextField type="date" name="employementInternshipStartDate" />
				<TextField type="date" name="employementInternshipEndDate" />
			</Fieldset>
			<TextField name="employementInternshipPosition" multiline />
			<TextField name="employementInternshipEnvironment" multiline />
			<TextField name="employementInternshipManager" />
			<TextField name="employementInternshipReason" multiline />
			<Fieldset horizontal>
				<TextField name="employementInternshipStartSalary" type="number" />
				<TextField name="employementInternshipExitSalary" type="number" />
			</Fieldset>
		</Fieldset>
		<Fieldset>
			<Typography variant="h2">Références</Typography>
			<FieldsetContainer>
				<CardFieldset sx={{ flexGrow: 1 }} label="Référence n°1">
					<TextField name="info1" />
					<TextField name="email1" type="email" />
					<TextField name="phone1" />
				</CardFieldset>
				<CardFieldset sx={{ flexGrow: 1 }} label="Référence n°2">
					<TextField name="info2" />
					<TextField name="email2" type="email" />
					<TextField name="phone2" />
				</CardFieldset>
			</FieldsetContainer>
			<CheckboxField name="consent" />
		</Fieldset>
		<Fieldset>
			<Typography variant="h2">Activité souhaitée</Typography>
			<TextField name="desiredFunction" />
			<TextField name="desiredSalary" type="number" />
			<PicklistField name="desiredCity" picklist={props.picklists.location} />
			<TextField name="desiredAvailability" type="date" />
		</Fieldset>
		<Fieldset>
			<Typography variant="h2">A propos de vous</Typography>
			<TextField name="aboutYouStrength" multiline />
			<TextField name="aboutYouWeaknesses" multiline />
			<TextField name="aboutYouSources" multiline />
		</Fieldset>
		<Fieldset>
			<Typography variant="h2">Politique de confidentialité</Typography>
			<CheckboxField
				name="privacyConsent"
				label={
					<React.Fragment>
						J&apos;ai lu et j&apos;accepte les termes de&nbsp;
						<Link to={"https://www.solutec.fr/fr/mentions-legales/"}>
							la politique de confidentialité
						</Link>
					</React.Fragment>
				}
			/>
		</Fieldset>
	</React.Fragment>
)

/**
 * The page to file in candidate file forms
 *
 * @returns
 */
const CandidateFile = () => {
	const loaderData = useLoaderData() as TLoaderData
	const picklists = usePicklists()
	const metadata = {
		picklists: picklists,
	}
	const schema = getSchema(metadata)

	const handleSubmit = async (
		data: TDataForm,
		formikHelpers: FormikHelpers<TDataForm>
	) => {
		const response = await updateCandidateFile(
			loaderData.candidateId,
			loaderData.key,
			schema.validateSync(data)
		)
		if (!response.ok) {
			const responseData = await response.json()
			if (!isServerError(responseData)) {
				throw new Error(errorSchema.validateSync(responseData).toString())
			}
			setError(responseData)
		} else {
			pushNotif(<SyncSuccessAlert />)
		}
		formikHelpers.setSubmitting(false)
	}

	const [ErrorAlert, setError] = useServerErrorAlert()
	const [NotificationFeed, pushNotif] = useNotificationFeed({
		timings: {
			slideInDuration: 500,
			fadeOutDelay: 2000,
			fadeOutDuration: 2000,
		},
	})

	return (
		<ThemeProvider theme={formTheme}>
			<ErrorAlert />
			<NotificationFeed />
			<Await resolve={loaderData.existingData}>
				{(existingData: TAwaitedData) => (
					<MainBox variant="column" id="mainBox">
						<FormTitle lastModified={existingData.lastModified}>
							Dossier <Accent>candidat</Accent>
						</FormTitle>
						<Form
							schema={schema}
							initialValues={genFormInit(schema, existingData)}
							onSubmit={handleSubmit}
							disabled={loaderData.permission === "view"}
						>
							<FormContent picklists={picklists} />
						</Form>
					</MainBox>
				)}
			</Await>
		</ThemeProvider>
	)
}

export default CandidateFile
export { loader }
