/**
 * A button for opening the navigation menu on small screens
 * mimicking [solutec.fr](https://www.solutec.fr/fr/)
 *
 * @module
 */
import { Box } from "@mui/material"
import { useTheme } from "@mui/material/styles"

/** {@link MenuBurger | `<MenuBurger>`} properties type /*/
type TMenuBurgerProps = {
	/**
	 * If `true`, render a cross shaped close button.
	 * If `false`, render a burger shaped menu button.
	 */
	crossed: boolean
}

/**
 * A button for opening the navigation menu on small screens
 * mimicking [solutec.fr](https://www.solutec.fr/fr/)
 *
 * @group Components
 */
const MenuBurger = ({ crossed }: TMenuBurgerProps) => {
	const theme = useTheme()
	return (
		<Box
			sx={{
				position: "relative",
				minWidth: 40,
				maxWidth: 40,
				minHeight: 40,
				maxHeight: 40,
			}}
		>
			<Box
				sx={{
					position: "absolute",
					left: 10,
					top: crossed ? 19 : 12,
					height: 2,
					width: 20,
					backgroundColor: theme.palette.primary.contrastText,
					transition: "all 300ms",
					transform: crossed ? "rotate(-45deg)" : undefined,
				}}
			></Box>
			<Box
				sx={{
					position: "absolute",
					left: 10,
					top: 19,
					height: 2,
					width: 20,
					backgroundColor: crossed
						? theme.palette.primary.main
						: theme.palette.primary.contrastText,
					transition: "background-color 300ms",
				}}
			></Box>
			<Box
				sx={{
					position: "absolute",
					left: 10,
					bottom: crossed ? 19 : 12,
					height: 2,
					width: 20,
					backgroundColor: theme.palette.primary.contrastText,
					transition: "all 300ms",
					transform: crossed ? "rotate(45deg)" : undefined,
				}}
			></Box>
		</Box>
	)
}

export default MenuBurger
export type { TMenuBurgerProps }
