/**
 * A [React context](https://react.dev/learn/passing-data-deeply-with-context)
 * to provide custom form fields with
 * [yup](https://github.com/jquense/yup) schema metadata
 *
 * @module
 */
import * as React from "react"

import { object, AnyObjectSchema, SchemaFieldDescription } from "yup"

const schemaContext = React.createContext<AnyObjectSchema>(object().shape({}))

const useSchema = () => React.useContext(schemaContext)

/**
 * The [React context](https://react.dev/learn/passing-data-deeply-with-context)
 * provider to provide custom form fields with
 * [yup](https://github.com/jquense/yup) schema metadata
 *
 * @group Components
 */
const SchemaProvider = schemaContext.Provider

const useSchemaField = (name: string): SchemaFieldDescription => {
	const schema = useSchema()
	if (schema.fields[name] == null) {
		throw new Error(`Could not find field "${name}" info in schema`)
	}
	return schema.fields[name].describe()
}

/**
 * A [React hook](https://react.dev/learn/reusing-logic-with-custom-hooks)
 * to get a field label from the [yup](https://github.com/jquense/yup) schema
 *
 * @param name - The name of the field as defined in the [yup](https://github.com/jquense/yup) schema
 * @returns The label of the field as defined in the [yup](https://github.com/jquense/yup) schema
 *
 * @group Hooks
 */
const useSchemaLabel = (name: string): string => {
	const fieldDescription = useSchemaField(name)
	if (!("label" in fieldDescription) || fieldDescription.label == null) {
		throw new Error(`Could not find label in "${name}" field description`)
	}
	return fieldDescription.label
}

/**
 * A [React hook](https://react.dev/learn/reusing-logic-with-custom-hooks)
 * to get a field required attribute from the [yup](https://github.com/jquense/yup) schema
 *
 * @param name - The name of the field as defined in the [yup](https://github.com/jquense/yup) schema
 * @returns `true` if the field is defined as requuired
 * in the [yup](https://github.com/jquense/yup) schema. `false` otherwise.
 *
 * @group Hooks
 */
const useSchemaRequired = (name: string): boolean => {
	const fieldDescription = useSchemaField(name)
	if (!("optional" in fieldDescription)) {
		throw new Error(`Could not find optional in "${name}" field description`)
	}
	return !fieldDescription.optional
}

export { SchemaProvider, useSchemaLabel, useSchemaRequired }
