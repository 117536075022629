/**
 * A component that surround its children with a header and a footer
 * and provides a context containing callbacks to change their
 * content
 *
 * @module
 */
import * as React from "react"

import { Box, Paper } from "@mui/material"

const SetFooterContext = React.createContext<
	React.Dispatch<React.SetStateAction<React.ReactElement>>
>(() => {
	console.log("unset setfooter action") // TODO: error management
})
const SetHeaderContext = React.createContext<
	React.Dispatch<React.SetStateAction<React.ReactElement>>
>(() => {
	console.log("unset setheader action") // TODO: error management
})

/**
 * A hook to set the footer rendered by a parent
 * {@link WithHeaderAndFooter | `<WithHeaderAndFooter>`}
 *
 * @param footer - A react Element to replace the footer with
 *
 * @group Hooks
 */
const useWithFooter = (footer: React.ReactElement) => {
	const setFooter = React.useContext(SetFooterContext)
	React.useEffect(() => {
		setFooter(footer)
		return () => setFooter(<React.Fragment />)
	}, [footer, setFooter])
}

/**
 * A hook to set the header rendered by a parent
 * {@link WithHeaderAndFooter | `<WithHeaderAndFooter>`}
 *
 * @param footer - A react Element to replace the header with
 *
 * @group Hooks
 */
const useWithHeader = (header: React.ReactElement) => {
	const setHeader = React.useContext(SetHeaderContext)
	React.useEffect(() => {
		setHeader(header)
		return () => setHeader(<React.Fragment />)
	}, [header, setHeader])
}

/**
 * Render its children between a footer and a header that they can change using hooks.
 *
 * Child components can set the footer using {@link useWithFooter}
 * and the header using {@link useWithHeader}
 *
 * Can be nested.
 */
const WithHeaderAndFooter = (
	props: React.PropsWithChildren<Record<never, never>>
) => {
	const [footer, setFooter] = React.useState<React.ReactElement>(
		<React.Fragment />
	)
	const [header, setHeader] = React.useState<React.ReactElement>(
		<React.Fragment />
	)
	return (
		<SetFooterContext.Provider value={setFooter}>
			<SetHeaderContext.Provider value={setHeader}>
				<Paper
					component="header"
					sx={{
						position: "sticky",
						top: 0,
						width: "100%",
						display: "block",
						zIndex: 100,
					}}
				>
					{header}
				</Paper>
				<Box
					id="main-view"
					sx={{
						flexGrow: 1,
						overflow: "none",
					}}
				>
					{props.children}
				</Box>
				<Paper
					component="footer"
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					{footer}
				</Paper>
			</SetHeaderContext.Provider>
		</SetFooterContext.Provider>
	)
}

export default WithHeaderAndFooter
export { useWithFooter, useWithHeader }
