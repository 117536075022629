/**
 * Renders a {@link TServerError} in a styled
 * [Box](https://mui.com/material-ui/react-box/)
 *
 * @module
 */
import * as React from "react"
import { TServerError } from "backend/ServerError"

import { Box } from "@mui/material"

type TServerErrorProps = {
	/** A {@link TServerError} returned by the php backend */
	error: TServerError
}

/**
 * Renders a {@link ServerError} in a styled
 * [Box](https://mui.com/material-ui/react-box/)
 *
 * The embedded error is displayed in a collapsable Box.
 * Used by {@link components/Form/ServerErrorAlert!default | <`ServerErrorAlert`>}
 * to render the error
 *
 * @group Components
 */
const ServerError = (props: TServerErrorProps) => {
	return (
		<Box
			sx={{
				whiteSpace: "pre-wrap",
			}}
		>
			{props.error.message} ({props.error.code}) AT: {props.error.line} IN{" "}
			{props.error.file}
			{props.error.previous === null ? (
				<React.Fragment />
			) : (
				<Box component="details">
					<ServerError error={props.error.previous} />
				</Box>
			)}
		</Box>
	)
}

export default ServerError
export { type TServerErrorProps }
