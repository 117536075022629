/**
 * A custom checkbox field connected to
 * [yup](https://github.com/jquense/yup) and
 * [formik](https://formik.org/)
 *
 * @module
 */
import * as React from "react"

import { useField } from "formik"
import {
	useSchemaLabel,
	useSchemaRequired,
	ErrorHelperText,
	useDisableForm,
} from "components"
import {
	Checkbox,
	FormControl,
	FormHelperText,
	FormLabel,
	Box,
} from "@mui/material"

/**
 * {@link CheckboxField | `<CheckboxField>`} properties type
 */
type TCheckboxFieldProps = {
	/**
	 * The name of the field as described in the schema
	 *
	 * Used to connect to yup and formik and retreive label, errors, required status ...
	 */
	name: string
	/**
	 * A label for the field
	 *
	 * Defaults to the label defined in the schema if any (using {@link useSchemaLabel})
	 * A error is thrown if left undefined and no label is defined in the schema
	 */
	label?: React.ReactNode | undefined
}

/**
 * A custom checkbox field connected to
 * [yup](https://github.com/jquense/yup) and
 * [formik](https://formik.org/)
 *
 * Uses the contexts provided by {@link components/Form/SchemaContext}
 * and [formik](https://formik.org/) to retreive label, if the field is
 * required or not, error status and message, validation rule.
 *
 * Uses the context provided by {@link useDisableForm}
 * to disable itself if instructed to
 *
 * @group Components
 */
const CheckboxField = (props: TCheckboxFieldProps) => {
	const [formikField, formikMeta, formikHelpers] = useField<boolean>({
		name: props.name,
		type: "checkbox",
	})
	const schemaLabel = useSchemaLabel(props.name)
	const schemaRequired = useSchemaRequired(props.name)
	const formDisabled = useDisableForm()
	const isErrorState = formikMeta.error !== undefined && formikMeta.touched
	const helperText = isErrorState ? (
		<ErrorHelperText>{formikMeta.error}</ErrorHelperText>
	) : (
		<React.Fragment />
	)
	return (
		<FormControl error={isErrorState}>
			<Box
				sx={{
					display: "inline-flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<Checkbox
					name={props.name}
					checked={formikField.value}
					disabled={formDisabled}
					onChange={(e, _checked) => {
						formikHelpers.setTouched(true, false)
						formikField.onChange(e)
					}}
				/>
				<FormLabel required={schemaRequired}>
					{props.label ?? schemaLabel}
				</FormLabel>
			</Box>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	)
}

export default CheckboxField
export type { TCheckboxFieldProps }
