/**
 * A simple Styled Box formatting the LastModifiedDate as a subtitle
 * @module
 */
import { Typography, TypographyProps } from "@mui/material"

/**
 * The type of properties accepted by {@link LastModifiedDate | `<LastModifiedDate>`}
 *
 * Props from `TypographyProps` are passed to the inner component
 */
type TLastModifiedDateProps = TypographyProps & {
	/**
	 * Any string that can be parsed by
	 * [`Date.parse`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse)
	 */
	dateString: string
}

const formatLastModifiedDate = (lastModified: string | null): string => {
	if (lastModified == null) {
		return "Jamais modifié"
	}
	const lastModifiedDate = new Date(lastModified)
	let res = "Dernière modification le "
	res += lastModifiedDate.toLocaleDateString("fr-FR")
	res += " à "
	res += lastModifiedDate.toLocaleTimeString("fr-FR")
	return res
}

/**
 * A simple Styled Box formatting the LastModifiedDate as a subtitle
 *
 * The resulting string date is formatted using
 * [`<Typography>`](https://mui.com/material-ui/react-typography/)
 * with variant `"subtitle1"`
 * @group Components
 */
const LastModifiedDate = (props: TLastModifiedDateProps) => {
	const { dateString, ...otherProps } = props
	return (
		<Typography variant="subtitle1" {...otherProps}>
			{formatLastModifiedDate(dateString)}
		</Typography>
	)
}

export default LastModifiedDate
export type { TLastModifiedDateProps }
