/**
 * A round button shown when user's at top of the page
 *
 * @module
 */
import * as React from "react"

import { IconButton, IconButtonProps, Box } from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

/**
 * Type of the component props, are like IconButtonProps, but I had to add href even if it is a button props
 */
type TButtonScrollDown = {
	href: string
} & IconButtonProps

const ButtonScrollDown = (props: TButtonScrollDown) => {
	const [showButton, setShowButton] = React.useState<boolean>(true)

	React.useEffect(() => {
		window.addEventListener("scroll", () => {
			if (window.scrollY < 20) {
				setShowButton(true)
			} else {
				setShowButton(false)
			}
		})
	}, [])

	return (
		<Box
			sx={{
				position: "fixed",
				display: "flex",
				bottom: 5,
				width: "100%",
				alignContent: "center",
				zIndex: 100,
			}}
		>
			<IconButton
				size="large"
				sx={{
					marginX: "auto",
					textAlign: "center",
					display: showButton ? "inline-flex" : "none",
					borderRadius: "50%",
					padding: "20px",
				}}
				{...props}
			>
				<KeyboardArrowDownIcon />
			</IconButton>
		</Box>
	)
}

export default ButtonScrollDown
