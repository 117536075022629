/**
 * A container to place fieldsets in a row
 * @module
 */

import { Box, BoxProps } from "@mui/material"

/**
 * A container to place fieldsets in a row
 *
 * @param props - mui `Box` properties : passed to the inner component
 * It simply is a styled [Box](https://mui.com/material-ui/react-box/)
 * @group Components
 */
const FieldsetContainer = (props: BoxProps) => {
	const { sx, ...otherProps } = props
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				flexWrap: "wrap",
				rowGap: 2,
				columnGap: 3,
			}}
			{...otherProps}
		/>
	)
}

export default FieldsetContainer
