/**
 * ## TODO
 * Sending, receiving, and processing requests and responses should go through a client layer.
 * The client could do proper error handling
 *
 * @module
 */

import { TJobPosting } from "backend/JobPosting"
import { checkError } from "utils/errors"

/**
 * Fetch a job posting from the php backend
 *
 * ## TODO
 * Add error management.
 * This function may return something that is not
 * a `TJobPosting` if anything goes wrong on the php side.
 * Return type should be something like `Promise<TJobPosting | ServerError>`
 * The whole logic should be delegated to a proper client.
 * See {@link backend/JobPosting/queries}
 *
 * @param id - The id of the JobPosting
 * @returns A promise containing the job posting
 */
const getJobPosting = async (id: number): Promise<TJobPosting> => {
	const request = {
		method: "GET",
	}
	const params = new URLSearchParams({
		id: id.toString(),
	})
	const response = await fetch(
		process.env.REACT_APP_BACKEND_URL + "/JobPosting.php?" + params,
		request
	)

	await checkError(response)
	return await response.json()
}

/**
 * Fetch every job postings that are in a certain job family from the php backend
 *
 * ## TODO
 * Add error management.
 * This function may return something that is not
 * a `TJobPosting[]` if anything goes wrong on the php side.
 * Return type should be something like `Promise<TJobPosting[] | ServerError>`
 * The whole logic should be delegated to a proper client.
 * See {@link backend/JobPosting/queries}
 *
 *  @param idJobFamily - The id of the JobFamily group
 * @returns A promise containing an array of job postings
 */
const getAllJobPostingByJobFamily = async (
	idJobFamily: number
): Promise<TJobPosting[]> => {
	const request = {
		method: "GET",
	}
	const params = new URLSearchParams({
		idJobFamily: idJobFamily.toString(),
	})
	const response = await fetch(
		process.env.REACT_APP_BACKEND_URL + "/JobPosting.php?" + params,
		request
	)
	return await response.json()
}

/**
 * Fetch every job postings from the php backend
 *
 * ## TODO
 * Add error management.
 * This function may return something that is not
 * a `TJobPosting[]` if anything goes wrong on the php side.
 * Return type should be something like `Promise<TJobPosting[] | ServerError>`
 * The whole logic should be delegated to a proper client.
 * See {@link backend/JobPosting/queries}
 *
 * @returns A promise containing an array of joob postings
 */
const getAllJobPosting = async (): Promise<TJobPosting[]> => {
	const request = {
		method: "GET",
	}
	const response = await fetch(
		process.env.REACT_APP_BACKEND_URL + "/JobPosting.php",
		request
	)
	return await response.json()
}

export { getJobPosting, getAllJobPosting, getAllJobPostingByJobFamily }
