/**
 * A styled box formatting form title and subtitle
 * @module
 */
import * as React from "react"

import { Box, Typography } from "@mui/material"
import { LastModifiedDate } from "components"

type TFormTitleProps = {
	/**
	 * A string containing the lastModified date to be rendered
	 * with {@link LastModifiedDate | `<LastModifiedDate>`} as a subtitle
	 */
	lastModified: string
	/**
	 * The main title content
	 */
	children: React.ReactNode
}

/**
 * A styled box formatting the title and subtitle of a form
 *
 * @group Components
 */
const FormTitle = (props: TFormTitleProps) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography variant="h1">{props.children}</Typography>
			<LastModifiedDate dateString={props.lastModified} />
		</Box>
	)
}

export default FormTitle
export type { TFormTitleProps }
