/**
 * Render a share button.
 * Pop up a modal with differents options, each option share the link of the current page to the selected network
 *
 * @module
 */
import * as React from "react"

import RollButton from "./RollButton"
import {
	SvgIcon,
	Menu,
	MenuItem,
	IconButton,
	Snackbar,
	Alert,
} from "@mui/material"

import ShareIcon from "@mui/icons-material/Share"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

const ShareButton = () => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const [openSnackbar, setOpenSnackbar] = React.useState(false)

	// Add network behavior here
	const handleShare = (externalName: string) => {
		const encodedUrl = encodeURIComponent(window.location.href)
		let urlToOpen = ""
		switch (externalName) {
			case "linkedin":
				urlToOpen = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=My%20Page&summary=Check%20out%20this%20page!&source=MyWebsite`
				break
		}

		if (urlToOpen) {
			window.open(urlToOpen, "_blank")
		}

		handleClose()
	}

	const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleCopyUrl = async () => {
		try {
			await navigator.clipboard.writeText(window.location.href)
			console.log("URL copied to clipboard")

			setOpenSnackbar(true)
		} catch (err) {
			console.error("Failed to copy URL: ", err)
		} finally {
			handleClose()
		}
	}

	const handleCloseSnackbar = (
		event?: Event | React.SyntheticEvent,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return
		}
		setOpenSnackbar(false)
	}

	return (
		<div>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity="success"
					sx={{ width: "100%" }}
				>
					URL copiée dans le presse-papier!
				</Alert>
			</Snackbar>
			<RollButton
				type="button"
				variant="outlined"
				color="primary"
				onClick={handleOpen}
				sx={{ height: "56px" }}
			>
				<SvgIcon component={ShareIcon} sx={{ marginRight: "10px" }} />
				Partager
			</RollButton>
			{/** Add networks share buttons here */}
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				<MenuItem onClick={() => handleShare("linkedin")}>
					<IconButton>
						<LinkedInIcon />
					</IconButton>
					Partager sur LinkedIn
				</MenuItem>
				<MenuItem onClick={handleCopyUrl}>
					<IconButton>
						<ContentCopyIcon />
					</IconButton>
					Copier le lien
				</MenuItem>
			</Menu>
		</div>
	)
}

export default ShareButton
