/**
 * Displays a {@link TServerError} to the user in a popup
 * @module
 */
import * as React from "react"

import { AlertDialog, ServerError } from "components"
import { TServerError } from "backend/ServerError"

type TServerErrorAlertProps = {
	error: TServerError | null
	setError: React.Dispatch<React.SetStateAction<TServerError | null>>
}

const ServerErrorAlert = (props: TServerErrorAlertProps) =>
	props.error === null ? (
		<React.Fragment />
	) : (
		<AlertDialog
			open={props.error !== null}
			handleClose={() => props.setError(null)}
			userMessage="Erreur interne au serveur. Réessayez plus tard ou contactez un administrateur"
			moreInfo={<ServerError error={props.error} />}
		/>
	)

/**
 * A [React hook](https://react.dev/learn/reusing-logic-with-custom-hooks)
 * to set up server alerts display.
 *
 * Uses {@link AlertDialog |`<AlertDialog>`} to render a scrollable popup
 * containing a collapsable
 *
 * @returns `[Component, showError]`. `Component` takes no property and renders the popup.
 * `showError(error)` activates the popup and displays `error`.
 */
const useServerErrorAlert = (): [
	React.FC<Record<string, never>>,
	(error: TServerError) => void
] => {
	const [error, setError] = React.useState<TServerError | null>(null)
	const errorRef = React.useRef<TServerError | null>(null)
	errorRef.current = error
	const Component = React.useCallback(
		() => <ServerErrorAlert error={errorRef.current} setError={setError} />,
		[]
	)
	const showError = (error: TServerError) => setError(error)
	return [Component, showError]
}

export default useServerErrorAlert
