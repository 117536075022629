/**
 * Object schema and conversion functions for Interview report data.
 *
 * @module
 */

import { getPLValidator } from "backend/Picklist"
import { TPicklists } from "backend/Picklist/types"
import { TRatingScales, getRSTypesguards } from "backend/RatingScales"
import {
	numberEmptyStringToUndefined as number,
	date,
	enumOption,
} from "utils/yup"
import { object, string, mixed } from "yup"
import { TDataSchema, TDataPost } from "backend/InterviewReport"
import { undefinedToNull } from "utils/undefinedToNull"

/**
 * Generates a [yup](https://github.com/jquense/yup) schema describing an interview report
 *
 * @remarks
 * This schema is then used by [formik](https://formik.org/) to generate form.
 * See {@link "components/Form/Form"}
 * It is also used to derive data types representing form data. See {@link "backend/InterviewReport/types"}
 *
 * @param metadata - Data from success factor on which the schema depends
 * @param interviewNumber - 1 or 2 for the first or second interview
 * @returns A [yup](https://github.com/jquense/yup) schema describing interview report data
 */
const getSchema = (metadata: {
	picklists: TPicklists
	ratingScales: TRatingScales
	interviewNumber: number
}) => {
	const today = new Date()
	const plValidator = getPLValidator(metadata.picklists)
	const rsTypeguards = getRSTypesguards(metadata.ratingScales)

	let followUpTypesguards
	switch (metadata.interviewNumber) {
		case 1:
			followUpTypesguards = enumOption(plValidator.interview1FollowUp)
			break

		case 2:
			followUpTypesguards = enumOption(plValidator.interview2FollowUp)
			break

		default:
			throw new Error("Wrong interview number!")
	}
	return object({
		// Background
		diploma: string().max(100).label("Type de Bac"),
		diplomaYear: number()
			.required()
			.min(0)
			.max(today.getFullYear())
			.label("Année d’obtention"),
		diplomaMention: string().max(100).label("Mention"),
		training: string().max(4000).required().label("Parcours académique"),
		experience: string()
			.max(4000)
			.required()
			.label("Expérience professionnelle et environnement technique"),
		// Rating
		relationalRating: mixed(rsTypeguards.baseScale)
			.required()
			.label("Relationnel"),
		evolutionPotentialRating: mixed(rsTypeguards.baseScale)
			.required()
			.label("Potentiel d’évolution"),
		experiencedescriptionRating: mixed(rsTypeguards.baseScale)
			.required()
			.label("Facilité à décrire son expérience (discours clair et structuré)"),
		elocutionRating: mixed(rsTypeguards.baseScale)
			.required()
			.label("Niveau d’élocution (qualité d’expression)"),
		flexibilityRating: mixed(rsTypeguards.baseScale)
			.required()
			.label("Ouverture d’esprit / flexibilité"),
		interestRating: mixed(rsTypeguards.baseScale)
			.required()
			.label("Intérêt pour SOLUTEC"),
		appearanceRating: mixed(rsTypeguards.baseScale)
			.required()
			.label("Présentation physique"),
		travelFlexibilityRating: mixed(rsTypeguards.baseScale)
			.required()
			.label("Flexibilité temps de trajet"),
		ratingComments: string()
			.max(4000)
			.label("Remarques générales sur l’évaluation"),
		// Foreign languages
		englishLevel: mixed(rsTypeguards.optionalScale).required().label("Anglais"),
		languageSkillsComment: string()
			.max(4000)
			.label("Commentaires (autres langues maîtrisées notamment)"),
		// Desired activity
		position: string().max(4000).label("Métier/poste"),
		otherApplications: string()
			.max(4000)
			.label("Autres processus de recrutement en cours"),
		criterion1: string().max(100).required().label("Critères de choix 1"),
		criterion2: string().max(100).label("Critères de choix 2"),
		criterion3: string().max(100).label("Critères de choix 3"),
		desiredActivityOtherComments: string().max(4000).label("Autre"),
		// Availability
		dateOfDecision: date().required().label("Date de prise de décision"),
		dateOfAvailability: date().required().label("Disponibilité"),
		availabilityComments: string().max(4000).label("Commentaires"),
		// Salary
		currentSalary: number()
			.integer()
			.min(0)
			.label("Rémunération actuelle (si en poste)"),
		desiredSalary: number()
			.required()
			.integer()
			.min(0)
			.label("Montant souhaité par le candidat"),
		desiredSalaryAssessment: enumOption(plValidator.salaryAssessment)
			.required()
			.label("Appréciation"),
		salaryComments: string().max(4000).label("Commentaires"),
		// Test
		algorithmicTestResult: number().min(-40).max(40).label("Algorithmie"),
		logicTestResult: number().min(-11.25).max(45).label("Logique"),
		// Follow up
		interviewRating: mixed(rsTypeguards.preciseScale)
			.required()
			.label(
				"Note " +
					(metadata.interviewNumber === 1 ? "premier" : "deuxième") +
					" entretien"
			),
		overallAssessment: string()
			.max(4000)
			.label("Commentaire global (dont motif si non retenu)"),
		followUp: followUpTypesguards.required().label("Suite à donner"),
	})
}

/**
 * Convert data to a format accepted by the php backend
 *
 * @remark
 * This function converts undefined values to null using {@link undefinedToNull}
 *
 * @param formData - Form data validated and cast by the schema
 * @returns The candidate file data in a format accepted by the backend
 */
const intoBackendData = (formData: TDataSchema): TDataPost =>
	undefinedToNull(formData)

export default getSchema
export { intoBackendData }
