/**
 * A context to disable a whole form
 * @module
 */
import * as React from "react"

/**
 * A boolean context
 *
 * The value `true` indicates that the form is disabled
 */
const disableFormContext = React.createContext<boolean>(false)

/**
 * The context hook
 *
 * Used by input components to set their disabled state (if not overriden).
 * see
 * {@link components/Form/TextField!default | `<TextField>`}
 * {@link components/Form/CheckboxField!default | `<CheckboxField>`}
 * {@link components/Form/PicklistField!default | `<PicklistField>`}
 * {@link components/Form/RatingField!default | `<RatingField>`}
 *
 * @group Hooks
 */
const useDisableForm = () => React.useContext(disableFormContext)

/**
 * The context provider
 *
 * embed a for in this component with `value={true}` to disable the whole form
 * (given that you are using the input components defined in
 * {@link components})
 * @group Components
 */
const DisableForm = disableFormContext.Provider

export default DisableForm
export { useDisableForm }
