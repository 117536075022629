/**
 * @module SearchContext
 * Hook context used to pass search bar arguments between components
 * Component which use it should be within the parent SearchProvider
 */
import * as React from "react"

/**
 * Type definition for search context
 * @typedef {Object} TSearchContext
 * @property {string} searchinput - State variable for search input
 * @property {function} setsearch - Function to update search input
 * @property {number} locationinput - State variable for location input
 * @property {function} setlocation - Function to update location input
 * @property {number} jobtypeinput - State variable for job type input
 * @property {function} setjobtype - Function to update job type input
 */
type TSearchContext = {
	searchinput: string
	setsearch: (searchinput: string) => void
	locationinput: number
	setlocation: (locationinput: number) => void
	jobtypeinput: number[]
	setjobtype: (jobtypeinput: number[]) => void
}

export const SearchContext = React.createContext<TSearchContext | null>(null)

/**
 * Implementing the SearchProvider component
 * @param {Object} props - Props passed to the component
 * @returns {ReactElement} Rendered JSX
 */
export const SearchProvider = ({
	children,
}: React.PropsWithChildren<object>) => {
	const [searchinput, setsearch] =
		React.useState<TSearchContext["searchinput"]>("")
	const [locationinput, setlocation] =
		React.useState<TSearchContext["locationinput"]>(-1)
	const [jobtypeinput, setjobtype] = React.useState<
		TSearchContext["jobtypeinput"]
	>([])

	return (
		<SearchContext.Provider
			value={{
				searchinput,
				setsearch,
				locationinput,
				setlocation,
				jobtypeinput,
				setjobtype,
			}}
		>
			{children}
		</SearchContext.Provider>
	)
}

/**
 * Implementing the useSearchContext hook
 * @returns {Object} Current search context
 */
export const useSearchContext = () => {
	const currentSearchContext = React.useContext(SearchContext)

	if (!currentSearchContext) {
		throw new Error(
			"useSearchUser has to be used within <SearchContext.Provider>"
		)
	}

	return currentSearchContext
}
