import * as React from "react"
import { Typography } from "@mui/material"
import { SxProps } from "@mui/system"

/** {@link Accent | `<Accent>`} properties type */
type TAccentProps = {
	children: React.ReactNode
	sx?: SxProps
}

/**
 * Renders its children in a
 * [`<Typography>`](https://mui.com/material-ui/react-typography/)
 * with `variant="accent"`
 * and `color: "primary.main"`
 *
 * See {@link themes/global}
 */
const Accent = ({ sx, children }: TAccentProps) => {
	return (
		<Typography
			variant="accent"
			component="span"
			sx={{ color: "primary.main", ...sx }}
		>
			{children}
		</Typography>
	)
}

export default Accent
export type { TAccentProps }
