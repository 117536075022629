/**
 * @module SearchBar
 * This module provides a React functional component that represents a search bar
 * Has to be used within a SearchContextProvider (see hooks)
 */
import * as React from "react"
import { Form, useNavigate } from "react-router-dom"

import {
	TextField,
	Box,
	Grid,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Checkbox,
} from "@mui/material"

import { RollButton } from "components"
import { usePicklists } from "backend/Picklist"
import { useSearchContext } from "hooks/useSearchContext"

/**
 * Type definition for SearchBar props
 * @typedef {Object} TSearchBarProps
 * @property {string} orientation - Orientation of the form fields (row or column)
 * @property {boolean} navigateOnReset - Flag indicating if navigation should occur after resetting the search bar
 */
type TSearchBarProps = {
	orientation: "row" | "column"
	navigateOnReset: boolean
}

const AllLocationString = "-- Tous les lieux --"

/**
 * SearchBar component
 * @param {TSearchBarProps} props - The properties passed to the component
 * @returns {JSX.Element} Returns a search bar form with keyword, location, and job type selection fields
 */
const SearchBar = (props: TSearchBarProps) => {
	const inputSearchRef = React.useRef()
	const inputLocationRef = React.useRef()
	const inputTypeRef = React.useRef()

	const navigate = useNavigate()

	const picklists = usePicklists()

	const { setsearch, setlocation, setjobtype } = useSearchContext()
	const { locationinput, searchinput, jobtypeinput } = useSearchContext()

	const handleSearch = (e: React.FormEvent) => {
		e.preventDefault()

		if (inputSearchRef.current !== undefined) {
			setsearch(inputSearchRef.current["value"])
		}

		if (inputLocationRef.current !== undefined) {
			if (inputLocationRef.current["value"] !== "") {
				setlocation(inputLocationRef.current["value"])
			}
		}

		if (inputTypeRef.current !== undefined) {
			setjobtype(inputTypeRef.current["value"])
		}

		navigate("/")
	}

	const handleReset = () => {
		setlocation(-1)
		setsearch("")
		setjobtype([])
		if (props.navigateOnReset) navigate("/")
	}

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection:
					props.orientation === "row"
						? "row"
						: props.orientation === "column"
						? "column"
						: "column",
				width: "100%",
			}}
		>
			<Form onSubmit={handleSearch} style={{ width: "100%" }}>
				<Grid
					container
					spacing={1}
					direction="row"
					justifyContent="space-between"
					alignContent="flex-end"
					alignItems="flex-end"
				>
					<Grid item xs>
						<TextField
							inputRef={inputSearchRef}
							fullWidth
							name="keyword"
							type="search"
							label="Recherche par mots-clés"
							value={searchinput}
							onChange={(event) => setsearch(event.target.value)}
							sx={{ "& .MuiInputBase-input": { height: "56px" } }}
						/>
					</Grid>
					<Grid item xs md={2}>
						<FormControl fullWidth>
							<InputLabel id="contract_label_id">Type de contrat</InputLabel>
							<Select
								labelId="contract_label_id"
								name="contract"
								inputRef={inputTypeRef}
								value={jobtypeinput}
								onChange={(event) => {
									const inputValue = event.target.value
									if (typeof inputValue === "string") {
										return
									}
									setjobtype(inputValue)
								}}
								fullWidth
								placeholder="Type de contrat de travail"
								sx={{ height: "56px" }}
								multiple
								renderValue={(selected) => {
									if (selected.length !== 0) {
										return picklists.contract
											.filter((contract) => selected.includes(contract.id))
											.map((contract) => contract.label)
											.join(", ")
									} else {
										return "Indifférent"
									}
								}}
								displayEmpty
							>
								{picklists.contract.map((contract) => (
									<MenuItem key={contract.id} value={contract.id}>
										<Checkbox checked={jobtypeinput.includes(contract.id)} />
										{contract.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs md={3}>
						<FormControl fullWidth>
							<InputLabel id="lieu_label_id">Lieu</InputLabel>
							<Select
								labelId="lieu_label_id"
								name="lieu"
								inputRef={inputLocationRef}
								value={locationinput}
								onChange={(event) => {
									const inputValue = event.target.value
									if (!isNaN(Number(inputValue)))
										setlocation(Number(inputValue))
								}}
								fullWidth
								placeholder="Localisation de l'agence"
								sx={{ height: "56px" }}
							>
								<MenuItem key="undefined" value={-1}>
									{AllLocationString}
								</MenuItem>
								{picklists.location.map((location) => (
									<MenuItem key={location.id} value={location.id}>
										{location.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={"auto"}>
						<Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em" }}>
							<RollButton
								type="button"
								variant="contained"
								color="secondary"
								onClick={handleReset}
								sx={{ height: "56px" }}
							>
								Effacer les filtres
							</RollButton>
						</Box>
					</Grid>
				</Grid>
			</Form>
		</Box>
	)
}

SearchBar.defaultProps = {
	keyword: "",
	location: AllLocationString,
}

export default SearchBar
