/**
 * An outlined fieldset with a title
 * @module
 */

import { BoxProps, Typography, useTheme } from "@mui/material"
import { Fieldset } from "components"

type TCardFieldsetProps = BoxProps & {
	/** A title for the fieldset. Displayed in primary color above it */
	label: string
}

/**
 * An outlined fieldset with a title
 * @group Components
 */
const CardFieldset = (props: TCardFieldsetProps) => {
	const { label, children, sx, ...boxProps } = props
	const theme = useTheme()
	return (
		<Fieldset
			sx={{
				...sx,
				borderStyle: "solid",
				borderWidth: 1,
				borderColor: theme.palette.primary.light,
				borderRadius: 3,
				paddingX: 2,
				paddingTop: 4,
				paddingBottom: 3,
				marginTop: 2,
				position: "relative",
			}}
			{...boxProps}
		>
			<Typography
				variant="h3"
				color="primary.light"
				sx={{
					position: "absolute",
					top: 0,
					transform: "translateY(-50%)",
					left: "20px",
					backgroundColor: theme.palette.background.default,
					paddingX: 1,
				}}
			>
				{label}
			</Typography>
			{children}
		</Fieldset>
	)
}

export default CardFieldset
export type { TCardFieldsetProps }
