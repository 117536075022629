/**
 * Wraps an object type and return a new type with optional properties
 * converted to nullable properties.
 *
 * If an object type `Obj` has an optional property :
 * ```
 * type Obj = {
 * 	name: string | number | Baguette | undefined
 * }
 * ```
 * in the resulting type `UndefinedToNull<Obj>` the property is required
 * (cannont be undefined) but nullable.
 * ```
 * UndefinedtoNull<Obj> <===> {
 * 	name: string | number | Baguette | null
 * }
 * ```
 */
type UndefinedToNull<T extends Record<string, unknown>> = Required<{
	[K in keyof T]: undefined extends T[K] ? T[K] | null : T[K]
}>

/**
 * Replace undefined properties of an object with `null`
 *
 * @param obj - Any object
 * @returns `obj` where any `undefined` valued property is assigned `null`
 */
const undefinedToNull = <T extends Record<string, unknown>>(
	obj: T
): UndefinedToNull<T> => {
	const res: Record<string, any> = {}
	for (const p of Object.keys(obj)) {
		const objLoose = obj as Record<string, unknown>
		res[p] =
			objLoose[p] === undefined || objLoose[p] === "" ? null : objLoose[p]
	}
	return res as UndefinedToNull<T>
}
export { type UndefinedToNull, undefinedToNull }
