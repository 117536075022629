/**
 * ## TODO
 * Sending, receiving, and processing requests and responses should go through a client layer.
 * The client could do proper error handling
 *
 * @module
 */

import {
	TRatingScales,
	TBaseScale,
	TOptionalScale,
	TPreciseScale,
} from "backend/RatingScales"

/**
 * Fetch every rating scales from the php backend
 *
 * ## TODO
 * Add error management.
 * This function may return something that is not
 * a `TRatingScales` if anything goes wrong on the php side.
 * Return type should be something like `Promise<TRatingScales | ServerError>`
 * The whole logic should be delegated to a proper client.
 * See {@link backend/RatingScales/queries}
 *
 * @returns A promise containing rating scales data
 */
const getRatingScales = async (): Promise<TRatingScales> => {
	const request = {
		method: "GET",
	}
	const response = await fetch(
		process.env.REACT_APP_BACKEND_URL + "/RatingScale.php",
		request
	)
	const ratingScales = (await response.json()) as Record<string, string[]>
	return {
		baseScale: ratingScales["Base"] as TBaseScale[],
		optionalScale: ratingScales["Optional"] as TOptionalScale[],
		preciseScale: ratingScales["Precise"] as TPreciseScale[],
	}
}

export { getRatingScales }
