import { isServerError, TServerError } from "backend/ServerError"

/**
 * A custom Error representing a failed GET query to the php backend
 */
class QueryError extends Error {
	constructor(
		public status: number,
		public statusText: string,
		path: string,
		public data: unknown
	) {
		super("The server returned an error on " + path)
	}
}

/**
 * A custom Error representing a failed GET query to the php backend in the format of a {@link TServerError | ServerError}
 */
class QueryServerError extends QueryError {
	constructor(
		status: number,
		statusText: string,
		path: string,
		public serverError: TServerError
	) {
		super(status, statusText, path, null)
	}
}

/**
 * A custom Error representing a failed GET query to the php backend because the page is deleted
 */
class DeletedError extends QueryServerError {
	constructor(
		message: string,
		status: number,
		statusText: string,
		path: string,
		serverError: TServerError
	) {
		super(status, statusText, path, serverError)
		this.message = message
	}
}

/**
 * A custom Error caused by an invalid URL
 * It can be thrown before a query is made to the php backend
 * or once the response is recieved
 */
class UrlInvalidError extends Error {
	constructor(url: string) {
		super("Invalid URL : " + url)
	}
}

/**
 * A function to validate that the response of a request to the php backend is not an error
 *
 * @param response the response from the php backend
 * @throws a custom Error when the response indicate that the request has failed
 */
async function checkError(response: Response) {
	if (!response.ok) {
		const path = new URL(response.url).pathname
		const { status, statusText } = response

		let text
		try {
			text = await response.text()
		} catch {
			throw new QueryError(status, statusText, path, null)
		}

		let body
		try {
			body = JSON.parse(text)
		} catch {
			throw new QueryError(status, statusText, path, text)
		}

		if (isServerError(body)) {
			switch ((body as TServerError).message) {
				case "Secret key not valid!":
				case "Wrong interview number!":
				case "JobPosting does not exist":
					throw new UrlInvalidError(path)
				case "JobPosting is deleted":
					throw new DeletedError(
						"Cette offre n'est plus disponible",
						status,
						statusText,
						path,
						body
					)
				default:
					throw new QueryServerError(status, statusText, path, body)
			}
		} else {
			throw new QueryError(status, statusText, path, body)
		}
	}
}

export {
	QueryError,
	QueryServerError,
	DeletedError,
	UrlInvalidError,
	checkError,
}
