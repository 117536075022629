/**
 * A modified version of the global
 * [theme](https://mui.com/material-ui/customization/theming/) for form pages
 *
 * @module
 */
import { createTheme } from "@mui/material/styles"
import globalTheme from "./global"

/**
 * A modified version of the global
 * [theme](https://mui.com/material-ui/customization/theming/) for form pages
 *
 * Reduces spacing and and font sizes to fit more fields on the screen
 */
const formTheme = createTheme(globalTheme, {
	spacing: (factor: number) => `${3 * factor}px`,
	typography: {
		accent: {
			fontWeight: 400,
		},
		link: {
			[globalTheme.breakpoints.up("lg")]: {
				fontSize: 18,
			},
			[globalTheme.breakpoints.down("lg")]: {
				fontSize: 16,
			},
		},
		body1: {
			lineHeight: 1.5,
			[globalTheme.breakpoints.up("lg")]: {
				fontSize: 19,
			},
			[globalTheme.breakpoints.down("lg")]: {
				fontSize: 16,
			},
		},
		body2: {
			lineHeight: 1.4,
			[globalTheme.breakpoints.up("lg")]: {
				fontSize: 18,
			},
			[globalTheme.breakpoints.down("lg")]: {
				fontSize: 16,
			},
		},
		h1: {
			fontFamily: ["Roboto Slab", "Roboto", "Arial", "sans-serif"].join(","),
			fontWeight: 400,
			[globalTheme.breakpoints.up("lg")]: {
				fontSize: 30,
			},
			[globalTheme.breakpoints.between("sm", "lg")]: {
				fontSize: 26,
			},
			[globalTheme.breakpoints.down("sm")]: {
				fontSize: 20,
			},
			letterSpacing: "0.01em",
			lineHeight: 1.25,
		},
		h2: {
			fontFamily: ["Roboto Slab", "Roboto", "Arial", "sans-serif"].join(","),
			fontWeight: 300,
			[globalTheme.breakpoints.up("lg")]: {
				fontSize: 30,
			},
			[globalTheme.breakpoints.between("sm", "lg")]: {
				fontSize: 26,
			},
			[globalTheme.breakpoints.down("sm")]: {
				fontSize: 20,
			},
			letterSpacing: 0,
		},
		h3: {
			fontFamily: ["Roboto Slab", "Roboto", "Arial", "sans-serif"].join(","),
			fontWeight: 400,
			[globalTheme.breakpoints.up("lg")]: {
				fontSize: 20,
			},
			[globalTheme.breakpoints.down("lg")]: {
				fontSize: 18,
			},
			letterSpacing: 0,
		},
		h4: {
			lineHeight: 1.5,
			fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),
			fontWeight: 450,
			[globalTheme.breakpoints.up("lg")]: {
				fontSize: 19,
			},
			[globalTheme.breakpoints.down("lg")]: {
				fontSize: 16,
			},
			letterSpacing: 0,
		},
		button: {
			fontSize: 16,
			fontWeight: 400,
			lineHeight: 1.5,
		},
	},
})

export default formTheme
