/**
 * Component to render a background image behind other childs components
 *
 * @module
 */
type TBackgroundHeaderProps = {
	backgroundPath: string
	height: string
}

const BackgroundHeader = (
	props: React.PropsWithChildren<TBackgroundHeaderProps>
) => {
	const style = {
		position: "relative",
		height: props.height,
		overflow: "hidden",
	} as React.CSSProperties

	const backgroundStyle = {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		backgroundImage: `url(${props.backgroundPath})`,
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		backgroundColor: "lightslategray",
		backgroundBlendMode: "soft-light",
		filter: "blur(2px)",
		zIndex: -1,
	} as React.CSSProperties

	return (
		<div style={style}>
			<div style={backgroundStyle}></div>
			<div style={{ position: "relative", zIndex: 1, height: "100%" }}>
				{props.children}
			</div>
		</div>
	)
}

export default BackgroundHeader
