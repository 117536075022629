/**
 * @module PreviewCard
 * This module provides a React functional component that displays a preview card for a job posting
 * Used in the Home route mainly
 */
import DOMPurify from "dompurify"
import { Link } from "react-router-dom"

import { Card, CardContent, Typography, Stack, Box } from "@mui/material"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import AssignmentIcon from "@mui/icons-material/Assignment"

import { TJobPosting } from "backend/JobPosting"
import { usePicklists } from "backend/Picklist"

import { Accent } from "components"

/**
 * Type definition for PreviewCard props
 * @typedef {Object} TPreviewCardProps
 * @property {TJobPosting} jobPosting - Job posting object
 */
type TPreviewCardProps = {
	jobPosting: TJobPosting
}

/**
 * PreviewCard component
 * @param {TPreviewCardProps} props - The properties passed to the component
 * @returns {JSX.Element} Returns a card that displays a preview of a job posting
 */
const PreviewCard = (props: TPreviewCardProps) => {
	const link: string = "/carriere/" + props.jobPosting.id

	const { location, contract } = usePicklists()
	const loc = location.find((l) => l.id === props.jobPosting.location)?.label
	const contype = contract.find((f) => f.id === props.jobPosting.jobType)?.label

	const jobDescription = DOMPurify.sanitize(props.jobPosting.jobDescription, {
		ALLOWED_ATTR: [],
	})

	return (
		<Card>
			<CardContent
				sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
			>
				<Box
					sx={{
						marginRight: "1em",
						minWidth: { xs: "50%", md: "20%" },
						overflowWrap: "unset",
					}}
				>
					<Link to={link} style={{ textDecoration: "none" }}>
						<Typography variant="h6">
							<Accent sx={{ fontWeight: "bold" }}>
								{props.jobPosting.jobTitle}
							</Accent>
						</Typography>
					</Link>
					<Stack direction="row" alignItems="center" gap={1}>
						<LocationOnIcon />
						<Typography variant="subtitle1">{loc}</Typography>
					</Stack>
					<Stack direction="row" alignItems="center" gap={1}>
						<AssignmentIcon />
						<Typography variant="subtitle2">{contype}</Typography>
					</Stack>
				</Box>
				<Box>
					<Typography
						variant="body1"
						sx={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "-webkit-box",
							WebkitLineClamp: { xs: 4, sm: 3, md: 2 },
							WebkitBoxOrient: "vertical",
							marginTop: "0.5em",
						}}
						dangerouslySetInnerHTML={{ __html: jobDescription }}
					></Typography>
				</Box>
			</CardContent>
		</Card>
	)
}

export default PreviewCard
